import React from 'react';
import { Auth, API } from 'aws-amplify';

import moment from 'moment';
import { Spinner } from '../ApiCaller.js';
import { getCommonOptions,getStaffName } from './chart_util.js';
import ReactEcharts from 'echarts-for-react';
import { TimeCachedQuery} from '../HelperClasses/TimeOptimizedQuery.js'
import { StatefulButton } from '../VideoReview/vid-util';

import './Summary_Shift.css'; // Link to the CSS Style sheet 


const dashedLine = 'path://M180 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z, M810 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40zm, M1440 1000 l0 -40 200 0 200 0 0 40 0 40 -200 0 -200 0 0 -40z';

/**
 * @brief Get the median time spent reviewing a clip and display it in the table format
 */ 
export const SummaryShift = ({
                                isSelected = true
                                ,shiftData
                                ,renderedCharts
                                ,activeFilters
                                ,staffDetails
                                ,onDataChange =()=>{console.log("No onDataChange defined for Summary Shift")}
                                ,...props}) => {

    const [trackedFilters,setFilters] = React.useState({});

    const [mCurrentPage,setPage] = React.useState(0);
    const [mTotalPages,setTotalPages] = React.useState(0);
    const [mRenderedCharts,setRendered] = React.useState(null);

    const cPageSize = null;
    
    /**
     * Effect that is called one time when the component is first loaded
     */
    React.useEffect(() => {
        //Configure a query to use in this context, set the time split for the new vs old as 1 day. The data in the SQL
        TimeCachedQuery.addQuery("ShiftSummary",{timeAmount:3,timeUnit:'day',splitDays: 3, queryFN:fetchData_split,responseFN:fetchResponse});
        
        
    },[]);
    //Listen for a change to the active filters: after apply or checkbox click
    React.useEffect(() => {
        if(!staffDetails){return;}
        if( trackedFilters.startDate != activeFilters.startDate
            || trackedFilters.endDate != activeFilters.endDate
            || trackedFilters.e3person != activeFilters.e3person
        ){
            //  console.log("Filters changed? ",JSON.parse(JSON.stringify(activeFilters)), JSON.parse(JSON.stringify(trackedFilters)));
            TimeCachedQuery.query("ShiftSummary",{staffDetails:staffDetails}
                                                ,{
                                                    mode: 'shiftFetch' 
                                                    ,filters: activeFilters
                                                });
            //Clear the tracked shift data
            onDataChange({
                src: 'shift'
                ,data: null
            })
        }else{
            console.log("No filter change?")
        }
        setFilters(activeFilters);
        
    },[activeFilters,staffDetails]);
    React.useEffect(() => {
        //Configure a query to use in this context, set the time split for the new vs old as 1 day. The data in the SQL
        if(shiftData){
            // console.log("Data loaded for shifts? ");
        }else{
            // console.log("Removed shiftData?")
        }
        if(shiftData||renderedCharts){
            if(props.loadingComplete){props.loadingComplete(true);}            
        }else{
            if(props.loadingComplete){props.loadingComplete(false);}            
        }
        
    },[shiftData,renderedCharts]);

   

    const formatReturnedShifts=(_shifts,_staffDetails)=>{
        let combinedShifts = [];
        // console.log("Staff details: ",_staffDetails);
        try {
            for(const split_ of _shifts){

                let shifts = (split_.data||[]).map(shift_=>{
                    //  console.log("Proces on: ",shift_)
                    try {
                        const data= {}
        
                        data.xdata =[];
                        data.reviewed =[];
                        if(shift_.summary){
                            //Extract the XData: (minutes)
                            data.xdata = Object.keys(shift_.summary).map(timestamp_=>{return timestamp_.slice(11)+":00";});
                            let tmp = Object.values(shift_.summary);
                            data.shifttime = tmp.map(elem_=>{return elem_.shift_time});
                            data.required = tmp.map(elem_=>{return elem_.required_sum});
                            data.reviewed = tmp.map(elem_=>{return elem_.reviewed_sum});
                            data.unviewed = tmp.map(elem_=>{return elem_.unviewed_sum});
                            data.viewed = tmp.map(elem_=>{return elem_.viewed_sum});    
                            data.sum = tmp.map(elem_=>{return elem_.sum});                                
                            
                        }
                        
                        const chartOptions = getCommonOptions("Shift",data);
        
                        // Configure the y-axis
                        chartOptions.yAxis.name = 'Time (Hours)';
                        chartOptions.yAxis.min = 0;
                        chartOptions.yAxis.max = 12;
        
                        // Configure the x-axis
                        chartOptions.xAxis[0].name = 'Time of Day (HH:MM:SS)';
                        chartOptions.xAxis[0].axisLabel.interval = 59;
                        chartOptions.xAxis[0].splitLine={show: true};
        
                        //Restrict the pop-up box to only render inside the chart's rectangle view
                        chartOptions.tooltip.confine = true;
        
        
                        // Configure the legend:
                        // chartOptions.legend.icon='line';
                        chartOptions.legend.data= [
                                {
                                    name: 'Ignored clips total time',
                                    icon: 'line',
                                },
                                {
                                    name: 'Shift Time',
                                    icon: dashedLine
                                },
                                {
                                    name: 'Click to click total time',
                                    icon: 'line',
                                },
                                {
                                    name: 'Ignored + Click to click total time',
                                    icon: 'line',
                                },
                                {
                                    name: 'Actual viewed time of clips',
                                    icon: dashedLine
                                },
                                // {
                                //     name: 'Total allotted time',
                                //     icon: dashedLine
                                // },
                            ];//end icon data set configuration
                        
                        
        
                        
        
                        chartOptions.title.text  = `${getStaffName(_staffDetails,shift_.username)} ${moment.utc(shift_.startdate).format('MMMM DD, YYYY')} (${shift_.shift} SHIFT)`
                        if(shift_.shift==='ON'){
                            chartOptions.title.text  = `${getStaffName(_staffDetails,shift_.username)} ${moment.utc(shift_.startdate).format('MMMM DD')} - ${moment.utc(shift_.startdate).add(1,'day').format('MMMM DD, YYYY')} (${shift_.shift} SHIFT)`
                        }
        
                         chartOptions.color=['#FF0000','#ED7D31','#4472C4','#00FF00','#000000','#FF33CC']
                        
                        // Add the series to plot on the Y axis
                        chartOptions.series = [
                            {
                                name: 'Ignored clips total time',
                                data: data.unviewed,
                                type: 'line',
                                symbol: 'none',
                            },
                            {
                                name: 'Shift Time',
                                data: data.shifttime,
                                type: 'line',
                                symbol: 'none',
                                lineStyle: {type: 'dashed'},
                            },
                            {
                                name: 'Click to click total time',
                                data: data.reviewed,
                                type: 'line',
                                symbol: 'none',
                            },
                            {
                                name: 'Ignored + Click to click total time',
                                data: data.sum,
                                symbol: 'none',
                                type: 'line',
                            },
                            {
                                name: 'Actual viewed time of clips',
                                data: data.viewed,
                                type: 'line',
                                symbol: 'none',
                                lineStyle: {type: 'dashed'},
                            },
                            // {
                            //     name: 'Total allotted time',
                            //     data: data.required,
                            //     type: 'line',
                            //     symbol: 'none',
                            //     lineStyle: {type: 'dashed'},
                            // },
                            
                        ];
        
        
                        return{
                            date: shift_.startdate.slice(0,10)
                            ,shift: shift_.shift
                            ,stats: shift_.stats
                            ,username: shift_.username
                            ,chart_data: data
                            ,chart_options: chartOptions
                        }
                    } catch (error) {
                        console.log("ERror in process: ",error);
                    }
                });
                // if(combinedShifts.length < 10){
                    combinedShifts.push(...shifts);
                // }
                
            }
    
            //Sort the shifts:
            let sortOrder = -1; //(-1 for descending, 1 for ascending)
            combinedShifts.sort((a, b) => {
                
                try {
                    //Sort by date
                    if( moment(a.date).isBefore(moment(b.date)) ){
                        return -1*sortOrder;
                    }
                    if (moment(a.date).isAfter(moment(b.date))) {
                        return 1*sortOrder;
                    }
                    // console.log("Same date: ",a.shift,b.shift);
                    //Dates are the same, fall back to shift priority
                    switch(a.shift){
                        case 'AM':{ //am shift is always first                                    
                            return -1*sortOrder;                                
                        }break;
                        case 'PM':{
                            if(b.shift==='AM'){return 1*sortOrder;}
                            if(b.shift==='ON'){return -1*sortOrder;}
                        }break;
                        case 'ON':{ //ON shift is always last                                    
                            // if(b.shift==='AM'){
                            return 1*sortOrder;
                        }break;
                    }//end switch on a.shift
                    // console.log("Same? ",a,b);
                    return 0;
                } catch (error) {
                    console.log("Failed to sort: ",a,b, error);
                }
            });

            
        } catch (error) {
            
        }
        if(cPageSize){
            let pages = combinedShifts.length/cPageSize;
            // console.log("Pages", pages, Math.ceil(pages));
            setTotalPages(Math.ceil(pages));
            // console.log("Shifts: ",combinedShifts);
            return combinedShifts.slice(0,cPageSize);
        }else{
            return combinedShifts;
        }
        

    }
    

    //Use the auto split from the TimeOptimized, this defines which API call to execute for the helper function
    const fetchData_split= (_options={})=>{
        
        //Configure the API call
        let serviceName= "TrifectaCoreAPI";//Set the servicename - this is the collection of lambdas
        let apiName= "/handleShiftPerformance"; //Set the specific lambda function to invoke
        //Execute the API request and return the promise

        //Combine the options from TimeOptimizedQuery with the specific needs from this query
        // let body = Object.assign(activeFilters,_options)
        // console.log("Fetch called: ",_options,activeFilters);
        //Trigger the query
        const apiResponse = API.post(serviceName,apiName, {body:_options});
        return apiResponse;
    }

    /**
     * Return callback from TimeOptimizedQuery. The optimizer waits for all queries to complete before returning
     * @param {*} _data 
     */
    const fetchResponse= (_return,_queryData)=>{
        // console.log("Return: ",_return,_queryData);
        //Merge all the arrays into one data set
        let shifts = formatReturnedShifts(_return,_queryData.staffDetails);

        let charts = shifts.map((shift_,idx)=>{
            return (
                <div key={`shiftplot-${idx}`}>
                    <ReactEcharts  option={shift_.chart_options} notMerge={true} 
                        style={{height: '45vh', width:'95vw'} } 
                    />
                    <div className="spacer"/>
                </div>
                    
                // </React.Fragment>
            )                              
        })

        setRendered(charts);


        onDataChange({
            src: 'shift'
            ,data: charts
        })
    }


    function onButtonClick(_direction){
        switch(_direction){
            default:
            case 'right':{
                setPage(current=>current+1);
            }break;
            case 'left':{
                setPage(current=>Math.max(current-1,0));
            }break;
        }
        
    }

    /**
     * @brief Listen for series selection
     */
    const render =() => {
        // console.log("render on shift?",renderedCharts);
        // console.log("isSelected: ",isSelected,props.hideRender)
        // console.log("render page: ",mCurrentPage,mTotalPages);
        // {shiftData.slice(mCurrentPage*cPageSize,(mCurrentPage+1)*cPageSize).map(shift_=>{
        let toRender = shiftData||renderedCharts;
        return (
                    (props.hideRender || !isSelected || (!toRender))? null :
                    <div className='shiftplots'>
                        <div className='plots'>
                            {/* {shiftData.map(shift_=>{
                                return (
                                    <React.Fragment>
                                        <ReactEcharts option={shift_.chart_options} notMerge={true} 
                                            style={{height: '45vh', width:'95vw'} } 
                                        />
                                        <div className="spacer"/>
                                    </React.Fragment>
                                )}
                            )} */}
                            {renderedCharts}
                        </div>
                        <div className='controls'>
                            <div>{`Number of charts loaded: ${toRender.length}`}</div>
                            {cPageSize&&<div>{`* Limited to the most recent ${cPageSize} shifts`}</div>}
                            <div>{' '}</div>
                            {/* <StatefulButton className="left btn btn-primary" onClick={()=>{onButtonClick('left')}} disabled={mCurrentPage===0}>Prev</StatefulButton>
                            <div className = 'pageCount'>{mCurrentPage}</div>
                            <StatefulButton className="right btn btn-primary" onClick={()=>{onButtonClick('right')}} disabled={mCurrentPage===(mTotalPages-1)}>Next</StatefulButton> */}
                        </div>

                    </div>
                );
    }//end render method
   
    return render();
};
