
import React from 'react';


import '../VideoCard.css';
import './NoteCard-Expanded.css';

import {  displayInfraction,displayWorkflow, getAlertIcon,OTHERHWTAGS,SEVERITIES,saveBlob } from '../Util.js';

import { Comment, Commenter } from '../NoteCard-Comments.js';

import { DropDownFC } from '../BaseComponents.js';

import * as moment from 'moment';

import hamburger from '../assets/hamburger-menu-icon-small.png';

import { Auth, API } from 'aws-amplify';
import { Spinner } from '../ApiCaller';

import { StatefulButton } from '../VideoReview/vid-util';

import { IoCallOutline } from 'react-icons/io5';
import {PhoneInterface} from './PhoneInterface.js'
import {NotecardDriverID } from './NotecardDriverID';
import {CutVideoNotecard} from '../VideoCut/CutVideoNotecard.js'


import { TagConfirm } from './NoteCard-TagConfirm';
import { isPermissionSet } from '../Util-access';

import { APILogger} from '../Util/APILogger.js'

const PHONE_TIMEOUT = 53*60; // Expire the phone icon if more than 45 minutes has elapsed


/* @Brief Component to wrap the display of the infraction type into buttons
*/
export const InfractionDisplay = ({text, onClick, editable}) =>{
    React.useEffect(() => {},[])
    function handleClick(_event){
        // console.log("Clicked:" ,text);
        if(onClick){onClick(text)}
    }
    return (
        <div className="infraction-display">
            <div className="infractiontext">{text}</div>
            {editable&&<StatefulButton className="remove" onClick={handleClick}>X</StatefulButton>}
      </div>
    )
}//end of InfractionDisplay component

  

/* @Brief Component to the Title of the notecard, this handles the severity color, date, alert icons and hamburger menu
*/
export const NCTitle = ({props, timeOfDay, onClick, severity}) =>{
    ///     variable, setter()    useState(InitialValue);
    const [displayDate, setDisplayDate] = React.useState();

    React.useEffect(() => {
        //Use the timeofday if available:
        let displayedDate = props.timeReceived && props.timeReceived.format("MMM D");
        try {
            displayedDate = timeOfDay && timeOfDay.format("MMM D");
        } catch (error) {}
        setDisplayDate(displayedDate);
    },[]) //call once on mount


    return (
        <div className="ncard-title">
            {/* Set the severity tag, this is done using css, and by staking the className */}
            <div className={"card-severity card-severity-" + severity}></div>
            <div className="audioalert">
                {props.metadata? <img src={getAlertIcon(props.metadata,'audio')} alt=""></img>:null}
            </div>
            <div className="vibrationalert">
                {props.metadata? <img src={getAlertIcon(props.metadata,'vibe')} alt=""></img>:null}
            </div>
            <div className="date-ham-pair">
                <div className="date">{displayDate}</div>
                <img className="hamburger" src={hamburger}  onClick={onClick}  alt='' />
            </div>
        </div>
    )
}//end of NCTitle component

/* @Brief Component to render the top half of the left panel, this is responsible for Asset details such as AssetID, SiteID, DriverID and Workflow
*/
export const NCLeftTop = ({props, driverName, top3Data, infractionTags, onDriverChange, videoURL, timeOfDay,noPhone}) =>{

    const [siteName, setSiteName] = React.useState();
    const [driverDisplay, setDriverDispaly] = React.useState(null); //hold the definition of the DriverID string (or button)
    const [typeAndTagText, setTypeAndTag] = React.useState(null); //hold the typeAndTag: eg: Posture-4
    const [canShowDriverID, toggleDriverID] = React.useState(null); //initialize to null so that the component is not loaded    
    const [canShowPhoneInterface, togglePhoneInterface] = React.useState(null); //initialize to null so that the component is not loaded
    const [showPhoneIcon, setShowPhone] = React.useState(1); //Initialize to disabled (will display in gray tone)
    const [VideoToPlay, setVideoFile] = React.useState(videoURL);
    const [TimeOfDay, setTimeOfDay] = React.useState();

    // Handle the initial conditions, get siteName from the loaded details
    React.useEffect(() => {
        // console.log("Left props: ",props);
        //Get the sitename, default value is the non formatted Site name
        let siteName = props.siteID;
        //Check the siteDetails to see if the Alias is available
        if(props.siteDetails && props.siteDetails[0] && props.siteDetails[0].alias){
            siteName = props.siteDetails[0].alias;
        }
        // console.log("Update site name:" ,siteName);
        //Update the site name to be dispalyed
        setSiteName(siteName);

        //Pass the infraction tags to get the best name to display on the card
        let typeAndTag = getTypeAndTagText(props);
        setTypeAndTag(typeAndTag);

    },[])

    React.useEffect(() => {
        //Use the timeofday if available:
        let displayedDate = props.timeReceived ;
        try {
            displayedDate = timeOfDay ;
        } catch (error) {}
        setTimeOfDay(displayedDate);
    },[timeOfDay]) //listen for update to TimeOfDay

    //Handle updating display of the phone interface
    React.useEffect(() => {
        
        setTypeAndTag(getTypeAndTagText(props)); //Update the type and tag if the tags change
        //Disable the phone icon if the input property has it set
        if(noPhone){   
            setShowPhone(0); //hide the phone icon
            return; 
        }

        try {
            //Is dispatch enabled for this site?
            if(props.siteDetails && props.siteDetails[0].options ){
                for(const option_ of props.siteDetails[0].options){ //iterate over the options array
                    //Check if the options has NoContact attribute:
                    if(option_.NoContact===true){
                        setShowPhone(0); //hide the phone icon
                        return;
                    }
                }
            }
        } catch (error) {}
        //  console.log("InfractionTags ", infractionTags)
        if(!infractionTags || infractionTags.length===0){setShowPhone(1); 
            // console.log("No tags");
            return;} //If there are no tags then disable the display

        // if(props.groupconfig && props.groupconfig.group.toLowerCase() === 'betagroup'){setShowPhone(2); return;} //Debug testing, allow the BetaGroup to always have the call option

        let newPhoneDisplayState = 1; //assume disable (initial state)
        //Make sure we know the site's timezone before determining if the phone can be shown:
        try {
            if(props.siteDetails && props.siteDetails[0].timezone){
                //Get the time data from the card and check to make sure it is recent
                let currentTimeOnSite = moment.tz(props.siteDetails[0].timezone).format("YYYY-MM-DD_HH-mm-ss");
                let timeOnCard = moment.parseZone(TimeOfDay).format("YYYY-MM-DD_HH-mm-ss");
                //Compute the time difference between the time the card was recorded and the current time at the site:
                let timeElapsedSeconds =  moment(currentTimeOnSite,'YYYY-MM-DD_HH-mm-ss').diff(moment(timeOnCard,'YYYY-MM-DD_HH-mm-ss'),'seconds');   
                //Only allow a call to happen if it was recorded in the last 30 minutes:
                let phoneTimeOut = PHONE_TIMEOUT; //local static configured time
                try {
                    //try to load the time from the SQL configuration
                    if(props.groupconfig && props.groupconfig.callwindow){phoneTimeOut = props.groupconfig.callwindow.expire*60}
                } catch (error) {console.log("No call window defined: ",error, props.groupconfig.callwindow);}
                // console.log("Timeout< ",phoneTimeOut,timeElapsedSeconds)
                if(timeElapsedSeconds < phoneTimeOut){
                    newPhoneDisplayState = 2; //enable the icon
                    // console.log("On time?", timeElapsedSeconds, TimeOfDay, timeOnCard,currentTimeOnSite);
                }else{ //notecard was tagged too late

                    newPhoneDisplayState = 1; //disable the icon
                    // console.log("time expired?", timeElapsedSeconds, TimeOfDay, timeOnCard,currentTimeOnSite);
                }
                
            }else{ //time is unknown
                newPhoneDisplayState = 1;//Don't show the icon to prevent a mistake in timezones:
                // console.log("No timezone?");
            }
        } catch (error) {//Unknown error            
            newPhoneDisplayState = 1;//Don't show the icon to prevent a mistakes:
            // console.log("error on eval?",error, props);
        }
        // newPhoneDisplayState = 2; //debugging - enable to allow phone calls always
        setShowPhone(newPhoneDisplayState);

    },[infractionTags,TimeOfDay]); //listen for changes in the InfractionTags
  
    //Handle updating the DriveriD and DriverID button when the data is available/changes
    React.useEffect(() => {
        //Change the driverid:
        // let nameProp = driverName || "Pending";
        let nameButton = <div className="name-text" > </div>;
        if(!driverName){
            nameButton =    <div className="name-text" > 
                                <div>DriverID: </div> 
                                <Spinner/>
                            </div>
        }else{
            let nameProp = driverName;
            nameProp = nameProp && nameProp.includes('DriverID:')?nameProp: nameProp? "DriverID: "+nameProp: "DriverID: ------"
            //Create the display for the name
            nameButton = <div className="name-text" >
                            {nameProp}
                        </div>
            //are we in the review groups? then enable the button to modify
            if(top3Data){
                if(nameProp){nameProp = nameProp.replace("DriverID: ","");}
                
                nameButton =    <div className="name-text">
                                    <div>DriverID: </div>
                                    <button className = "name-button" onClick={()=>toggleDriverID(true)}
                                    >{nameProp}</button>                                 
                                </div>    
            }
        }
        
        //Update the state variable to allow the render to process:
        setDriverDispaly(nameButton);

    },[driverName,top3Data]) //listen for changes to the Driver's name and Top3Data download

    // Wait for a videoURL to be updated, when updated set the local video to play
    React.useEffect(() => {
        if(!videoURL){return;}
        setVideoFile(videoURL);
    },[videoURL]) //watch for the videoURL to be updated from the parent, when it changes catch it here

    //Handle the DriverID changes when the submit button is called
    function handleDriverIDSubmit(_driverID){
        if(_driverID && onDriverChange){onDriverChange(_driverID)} //pass this back to Class state
        toggleDriverID(null); //set the state to null to disable loading the component               
    }

    return (
        <div className='card-basic-top'>
            <div className="expanded-name">
                {driverDisplay}
            </div>
            <div className="siteid">{props.groupconfig.group ==='reviewgroup'?null:"Site: "+siteName}</div>
            <div className="assetid">{props.groupconfig.group ==='reviewgroup'?null:"Asset: "+props.vehicleID}</div>
            {typeAndTagText?<div className="type">{typeAndTagText}</div>:null}                                        
            <div className="workflow">{displayWorkflow(props.status)}</div>
            
                <div className='phoneCall'>
                    <div className='phone-controls'>
                        {showPhoneIcon===2?
                            <div className='phone-button' onClick={()=>{togglePhoneInterface(true)}}><IoCallOutline className='phoneicon'/></div>
                        :
                            <React.Fragment>
                                {showPhoneIcon===1?<div className='phone-button-disabled' ><IoCallOutline className='phoneicon'/></div>:<div/>}         
                            </React.Fragment>
                        }
                        
                    </div>
                </div>
            
            
            {canShowDriverID&&  
                <NotecardDriverID 
                    groupconfig = {props.groupconfig}
                    driverid  = {driverName}
                    video = {VideoToPlay}  
                    scale = {props.scale}       
                    asset = {props.vehicleID}                 
                    onClose={handleDriverIDSubmit}
                    top3 = {top3Data}
                    infractionid = {props.infractionID}

                />    
            }
            {canShowPhoneInterface && 
                <PhoneInterface 
                    groupconfig = {props.groupconfig}
                    siteDetails= {props.siteDetails}
                    onClose={()=>{togglePhoneInterface(null)}} //set the state to null to disable loading the component
                    tags = {infractionTags}
                    infractionid = {props.infractionID}
                    assetid = {props.vehicleID}
                />
            }
    </div> 
    )
}// end NCLeftTop component


/* @Brief Component to rendering the bottom half of the left panel, this is responsible for the dropdown menu and infraction tags
*/
export const NCLeftBottom = ({props, infractionTags, infractionTagList, onTagChange}) =>{

    const [infractionTagsDisplay, setInfractionTags] = React.useState(); //List of infractions tagged on the card
    const [HWTagsDisplay, setHWTagsDisplay] = React.useState(); //List of HW that are tagged on the car
    const [infractionTagOptions, setInfractionTagOptions] = React.useState([]); //Options for the Clip dropdown (formatted for dropdown list)
    const [HWTagOptions, setHWTagOptions] = React.useState([]); //Options for the HW dropdown (formatted for dropdown list)
    
    const [displayHWTags, setDisplayHW] = React.useState(true); //Should the HW tags/dropdown be shown
    const [HWTags, setHWTagsAllowed] = React.useState(null); //The set of allowed HW tags (array of strings)
    const [tagToConfirm, setShowTag] = React.useState(null); //Open the confirmation box for monitored clips (Drowsiness, Cellphone, Severe Drowsiness, etc)
    const [isAllowedDrowsyRemove, setAllowedDrowsyRemove] = React.useState(false); //Does the current user have permissions to remove a Drowsy tag
    

    //Set initial logic on mount, populate the list of tags to display in the Dropdowns
    React.useEffect(() => {
        setDisplayHW(true);//enable view of the hw tags
        // console.log("Open with Props:" ,props);

        //Format the list of options for the Dropdown menu
        let infractionTagOptions = (infractionTagList || []).map(tag_ =>{
            return {
                text: tag_.type,
                value: tag_.type,
            };
        });
        //Add the alertset if present to the list of infractions for the dropdown menu
        try {
            (props.groupconfig.alertset || []).forEach(tag_=>{
                infractionTagOptions.push({  text:tag_.type, value: tag_.type });
            })
        } catch (error) {
            console.log("Failed to add alertset: ",error);
        }


        //Create the list of HW tags 
        let otherTags = [''];
        otherTags = otherTags.concat(
            OTHERHWTAGS.map(entry_ =>{return entry_.type})
        );

        try {
            
            //Limit the tags in the dropdown based on the user permissions:
            try {
                //Check if permissions are set to restrict tags for the is user
                if(isPermissionSet(props.groupconfig,"restricted_tags")){
                    //Get a list of all tags (set to lower case for easy comparison)
                    let restrictedTags = props.groupconfig.permissions['restricted_tags'].map(v => v.toLowerCase());
                    //Filter the list of tags to show using the set of restricted tags
                    infractionTagOptions = infractionTagOptions.filter( tag_ =>{ 
                        return !restrictedTags.includes(tag_.text.toLowerCase());
                    }) 
                }    
            } catch (error) { }    

            //Sort the options alphabetically
            infractionTagOptions.sort((a, b) => {
                const sortBy = (x) => (((a) => a))(x.value);
                if (sortBy(a) < sortBy(b)) {   return -1;    }
                if (sortBy(a) > sortBy(b)) {   return 1;    }
                return 0;
            });
        } catch (error) {}
        //Format the list of options for the Dropdown menu
        const otherTagOptions = (otherTags || []).map(tag_ =>{            
            return {
                text: tag_,
                value: tag_,
            };
        });

        //Set the options displayed in the dropdowns
        setInfractionTagOptions(infractionTagOptions);
        setHWTagOptions(otherTagOptions);
        //Retain the list of HW tags for comparison
        setHWTagsAllowed(otherTags);

        //Check if the user has permissions to remove drowsy tags:
        // if(isPermissionSet(props.groupconfig,"restricted_remove")){
        if(props.groupconfig && props.groupconfig.permissions && props.groupconfig.permissions.drowsyremove){
            setAllowedDrowsyRemove(true);
        }
    },[]) //no listerner, run once on mount

    //Handle updating the selected tags under the Dropdowns
    React.useEffect(() => {
        // console.log("Input: ",infractionTags, )
        const infractionTagDisplays = [];
        const HWTagDisplays = [];

        try{
            //Make sure both the infraction tags and the HW tags are available
            if(HWTags && infractionTags){
                //Parse the lists to separate the InfractionTags from the HWTags
                let infractionList = (infractionTags||[]).filter( (tag_) =>{
                    return !HWTags.includes(tag_);
                });
                let otherList = (infractionTags||[]).filter( (tag_) =>{
                    return HWTags.includes(tag_);
                });

                //Check if the edit permission is set to allow removing tags
                //If not set then canEdit will be false and the (x) icon will be removed
                let canEdit = isPermissionSet(props.groupconfig,"notecards",{removetag:true});
                //Add the detected tags to the list of tags to display on the notecard
                (infractionList||[]).forEach( (tag_,idx) =>{               
                    if(canEdit){ //IF edits are allowed, check if removing drowsy tags is allowed
                        canEdit = tag_.includes("Drows")?isAllowedDrowsyRemove:true;
                    }     
                    infractionTagDisplays.push(<InfractionDisplay text={tag_} key={tag_+idx} editable={canEdit} onClick={onRemoveInfraction}/>);
                    // infractionTagDisplays.push(<InfractionDisplay text={tag_} key={tag_+idx} editable={isPermissionSet(props.groupconfig,"restricted_removes",tag_)?isAllowedDrowsyRemove:true} onClick={onRemoveInfraction}/>);
                });
                (otherList||[]).forEach( (tag_,idx) =>{
                    if(canEdit){ //IF edits are allowed, check if removing drowsy tags is allowed
                        canEdit = tag_.includes("Drows")?isAllowedDrowsyRemove:true;
                    }     
                    HWTagDisplays.push(<InfractionDisplay text={tag_} key={tag_+idx} editable={canEdit} onClick={onRemoveInfraction}/>);
                    // HWTagDisplays.push(<InfractionDisplay text={tag_} key={tag_+idx} editable={isPermissionSet(props.groupconfig,"restricted_removes",tag_)?isAllowedDrowsyRemove:true} onClick={onRemoveInfraction}/>);
                });
            }
            
        }catch(error){
            console.log("Failed to parse lists: ",error);
        }
        // 
        //Update the lists that will be displayed
        setInfractionTags(infractionTagDisplays);
        setHWTagsDisplay(HWTagDisplays);

    },[infractionTags,HWTags]) //listen for changes to the tagged infractions, or HW tags list


    /* @Brief locally handle the removal of a tagged infraction. After updating the display variable, return the reduced set to the
        ExpandedCard to forward to the API and parent class state
    */
    function onRemoveInfraction(_data){
        // console.log("Remove infraction triggered: ",_data)

        if(props.groupconfig && props.groupconfig.driverid){return;} //disable the remove action if operator permissions are set
        if(props.noEdit){return;} //read-only don't allow modification

        // // console.log("Type removed: ",_type);
        //Don't allow the removal of Drowsiness tags without permissions:
         if(_data.includes('Drowsiness')){
        //     // console.log("Trying to remove a restricted type: ",this.state.currentUsername,this.props )
            if(props.groupconfig && props.groupconfig.permissions && !props.groupconfig.permissions.drowsyremove){
                return;//block the removal unless permision to remove is allowed to the user role
            }
        }
        //update the displayed set:
        if(onTagChange){  onTagChange({value: _data, action: 'remove'});}
    }

    /* @Brief locally handle the adding of a tagged infraction. After updating the display variable, return the reduced set to the
        ExpandedCard to forward to the API and parent class state
    */
    function handleTagChange(_value){
        if(!_value){return;}
        if(props.noEdit){return;} //read only mode enabled

        let tagSelected = _value.value;

        // console.log("Tag selected: ",tagSelected);
        
        //Do we need to confirm the tag before applying?
        let monitoredTags = ["Severe Drowsiness", "Seatbelt","Smartwatch","Egress","Drowsiness","Cellphone"]; //set the list of special cases
        if(monitoredTags.includes(tagSelected)){
            setShowTag({tag: tagSelected});
            return ;
        }else{
            //Call the tag change if we don't need confirmation
            if(onTagChange){onTagChange({value:tagSelected,confirmed:false});}
        }
    }

    return (
        <div className='card-basic-bottom'>
            <div>
                {props.groupconfig && props.groupconfig.driverid?
                    null :
                    <div className='dropdown-set'>
                        <div className="label-text">Clip </div>
                        <div className="infraction-dropdown-container">
                            <ul className="pcoded-item pcoded-right-item pcoded-right-align filter-drop-container">
                                
                                    <li className="dropdown-wrap">
                                        <DropDownFC options={infractionTagOptions} onChange={handleTagChange} value={" "} disabled={!isPermissionSet(props.groupconfig,"notecards",{addtag:true})}/>
                                    </li>
                            </ul>
                        </div>
                    </div>
                    
                }
                {infractionTagsDisplay}
            </div>
            {displayHWTags && 
                <div>
                    <div className='dropdown-set'>
                        <div className="label-text">Other </div>
                        <div className="infraction-dropdown-container">
                                <ul className="pcoded-item pcoded-right-item pcoded-right-align filter-drop-container">
                                    
                                        <li className="dropdown-wrap">
                                            <DropDownFC options={HWTagOptions} onChange={handleTagChange} value={" "}  disabled={!isPermissionSet(props.groupconfig,"notecards",{addtag:true})}/>
                                        </li>
                                </ul>
                        </div>                                                
                    </div>
                    {HWTagsDisplay}
                </div>
            }
            {(tagToConfirm) &&
                <TagConfirm 
                    {...tagToConfirm}                                                        
                    groupconfig = {props.groupconfig}
                    onClose={(_data)=>{ setShowTag(null);  }}    
                    onSubmit={onTagChange}                  
                />
            }
            
        </div>
        
    )
}//end of NCLeftBottom component


/* @Brief Component to handle the display of the Video, also releases the video player on exit
*/
export const NCVideo = ({props, videoURL, startTime}) =>{

    const [VideoToPlay, setVideoFile] = React.useState(); //Video file for the <Video> to play
    const videoRef = React.useRef(null); //Reference to the Video player to release on close

    // Wait for a videoURL to be updated, when updated set the local video to play
    React.useEffect(() => {
        if(!videoURL){return;}
        // console.log("Video file available: ",videoURL)
        setVideoFile(videoURL);
    },[videoURL]) //watch for the videoURL to be updated from the parent, when it changes catch it here

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        //Handle destroying the component
        return () => {
            // console.log("Release the video player");
            try {
                videoRef.current.src = null;
                videoRef.current.load();
            } catch (error) {
                // console.log("failed to release notecard video ",videoRef);
            }
        };
    },[]) //[] -> execute once

    // Render the HTML video component with the source file set for Safari compatibility
    return (
        <div className="card-date-avatar" >
                                        
        <video className='notecard-video' 
            id='notecard-video'
            // muted="muted"
            preload="auto"
            onCanPlay={()=>{console.log("Video ready to play: ",new Date()-startTime)}}
            controls
            controlsList="nodownload" //block the in player download request
            ref = {videoRef}
        > 
            {/* The source must contain the explicit type of video to work in Safari, also make sure
                that the video variable is valid before attempting the load (this isn't checked by the notecard) */}
            {VideoToPlay && <source src= {VideoToPlay} type="video/mp4"></source>}
        </video>
    </div>
    )
}//end of the NCVideo component


/* @Brief Component to handle rendering the comments block of the notecard, this includes editing and adding comments
   @param props: general this.props from ExpandedCard
   @param comments: array of comment objects from ExpandedCard this.state.comments
   @param username: username for the current user from ExpandedCard this.state.currentUserName
   @param onUpdate: callback function to Notify that the comments have changed (handled in the ExpandedCard)
*/
export const NCComments = ({props, comments, username, onUpdate}) =>{

    const [commentSet, setComments] = React.useState([]); //initialize to an empty array

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{ },[]) //[] -> execute once

    // Handle changes made to the set of Comments to display
    React.useEffect(()=>{ 
        // console.log("Comments updated?",comments);
        //Iterate over the comments passed down from the Notecard: 
        let commentsToDisplay = comments.map((comment_,index_) => {
            // Create a Comment component to wrap the comment text for each comment in the array
            return <Comment {...comment_} key ={"commentsxtr-"+index_} cardid={props.cardID} infractionID={props.infractionID} 
                        currentUser={username} commentindex={index_} totalCount={comments.length} disableEdits={props.noEdit}
                        updateComment={onUpdate} notecardtype={props.notecardtype}
                    />
        }).reverse(); //set revere chronological order
        //update the comments to display on the Card
        setComments(commentsToDisplay);

    },[comments]) //[] -> listen for changes to the comments param

    //Use a fragment when you need a placeholder but don't really want a new entry in the DOM
    return (
        <React.Fragment>
            { commentSet.length > 0 && 
                <div>
                    <div className="comments-title">Comments</div>
                    <div className="comments">
                        {commentSet}
                    </div>
                </div>
            }
         </React.Fragment>
    )
}//end of the NCComments component

/* @Brief Component handle rendering the bottom control buttons on the notecard, this includes, delete, add comment, irrelevant and download
*/
export const NCFooter = ({props, downloadLink,linkedVidID,infractionTags, onTagChange, onUpdateComment,...rest}) =>{

    const [isAddingComment, setAddingComment] = React.useState(null); //initialize to null - this will hold the current string being built by the new comment
    const [isDownloaded, setDownloaded] = React.useState(false); //initialize to false
    const [canShowButtons, setShowButtons] = React.useState(true); //initialize to true
    const [typeAndTagText, setTypeAndTag] = React.useState(null); //hold the typeAndTag: eg: Posture-4

    const [isEditDisabled, setDisabledEdits] = React.useState(props.noEdit); //initialize to true
    const [isCommentDisabled, setDisabledComments] = React.useState(props.noEdit); //initialize to true
    const [isDeleteDisabled, setDisabledDelete] = React.useState(false); //initialize to true
    const [isVideoCutDisabled, setDisabledCut] = React.useState(false); //initialize to true

    //Styles for the buttons
    const [styleDownload, setStyleDownload] = React.useState({visibility: 'hidden',opacity:"1"}); //initialize with hidden button
    const [styleIrrelevant, setStyleIrrelevant] = React.useState({visibility: 'hidden',opacity:"1"}); //initialize with hidden button
    const [styleDelete, setStyleDelete] = React.useState({visibility: 'hidden',opacity:"1"}); //initialize with hidden button
    const [styleCut, setStyleCut] = React.useState({visibility: 'hidden',opacity:"1"}); //initialize with hidden button

    //Control individual button toggles
    const [canShowVideoCut, toggleCutVideo] = React.useState(null); //initialize to true so that the component is not loaded
    const [canShowComment, toggleButton_comment] = React.useState(true); //initialize to true so that the component is not loaded
    const [canShowDownload, toggleButton_Download] = React.useState(true); //initialize to true so that the component is not loaded
    const [canShowCut, toggleButton_Cut] = React.useState(true); //initialize to null so that the component is not loaded
    const [canShowHighlight, toggleButton_Highlight] = React.useState(true); //initialize to true so that the component is not loaded
    const [canShowIrrelevant, toggleButton_Irrelevant] = React.useState(true); //initialize to true so that the component is not loaded
    const [canShowDelete, toggleButton_Delete] = React.useState(true); //initialize to true so that the component is not loaded

    //Handle the effect of creating the component, executes one time
    React.useEffect(()=>{
        // console.log("Notecard foot: ",props);
        //Check the permissions to disable the delete button:
        if(props.groupconfig && props.groupconfig.permissions && props.groupconfig.permissions.notecarddelete && 
            props.groupconfig.permissions.notecarddelete==='disabled'){
                setDisabledDelete(true);
        }
        //Add extra restrictions to the Site Manager, don't allow marking irrelevant or deleting a notecard
        if(props.filter && props.filter.role){
            (props.filter.role || []).forEach(role=>{
                if(role==='SiteManager'){
                    setDisabledEdits(true);
                }
            })
        }   

        //Check if removetag permission is set, if not then disable the delete and irrelevant:
        if(!isPermissionSet(props.groupconfig,"notecards",{removetag:true})){
            setDisabledEdits(true);
            setDisabledDelete(true);
        }

        //Check if videocut taag permission is set, if not then disable the delete and irrelevant:
        if(!isPermissionSet(props.groupconfig,"notecards",{videocut:true})){
            setDisabledCut(true);            
        }
        //Clean up on notecard exit
        return()=>{
            APILogger.emptyQueue();
        }

    },[]) //[] -> execute once (DO NOTHING)

    //Update the Irrelevant style, fade the button to show that it is not available
    React.useEffect(()=>{
        
        setTypeAndTag(getTypeAndTagText(props)); //Update the type and tag if the tags change
        
        //If edits are disabled show the button faded
        let irrelevantTmp = {visibility:"visible",opacity: '1'};

        if(isEditDisabled){
            // console.log("Edits are disabled");
            setStyleDelete({visibility:"visible", opacity: "0.2"});
            irrelevantTmp ={visibility:"visible", opacity: "0.2"};
        }
        else{
            setStyleDelete({visibility:"visible", opacity: "1"});
            // check the tags and permissions for the irrelevant button:
            try {                
                //If the Drowsiness/Severe Drowsiness are tagged, then check the user permissions
                if(infractionTags.includes('Drowsiness')){
                    console.log("Trying to remove a restricted type: ",this.state.currentUsername,this.props )
                    //Check that the user role has the drowsyremove permission, if not set the fade the button
                    if(props.groupconfig && props.groupconfig.permissions && !props.groupconfig.permissions.drowsyremove){
                        irrelevantTmp ={visibility:"visible", opacity: "0.2"};
                    }
                }    
            } catch (error) {}
        }
        //Hide the delete button when permissions are not set
        if(isDeleteDisabled){
            setStyleDelete({visibility:"hidden", opacity: "0.2"});
        }

        setStyleIrrelevant(irrelevantTmp);
    },[isEditDisabled,isDeleteDisabled,infractionTags]) //listen for changes to the infraction tags
     //Update the Delete style, fade the button to show that it is not available
    React.useEffect(()=>{
        if(isDeleteDisabled){
            setStyleDelete({visibility:"hidden", opacity: "0.2"});
        }else{
            setStyleDelete({visibility:"visible", opacity: "1"});
        }
    },[isDeleteDisabled]) //listen for changes to the delete button permission

    //Update the Irrelevant style, fade the button to show that it is not available
    React.useEffect(()=>{
        if(isVideoCutDisabled){
            setStyleCut({visibility:"hidden", opacity: "0.2"});
        }else{
            setStyleCut({visibility:"visible", opacity: "1"});
        }
    },[isVideoCutDisabled]) //listen for changes to the cut permission

    //Handle changes to the adding comment button or download link
    React.useEffect(()=>{
        try {
            //check is the add comment block is open
            if(isAddingComment){ //Adding comment block is opened
                setShowButtons(false);
            }else{ //not open
                setShowButtons(true);
                if(!downloadLink){ //check if there is a download URL to enable downloads:
                    // console.log("No DLURL",downloadLink);
                    setStyleDownload({visibility:"hidden", opacity: "1"});            
                }else{
                    //Check the user permissions to download a file                
                    if(props.groupconfig.permissions && props.groupconfig.permissions.carddownload 
                        && props.groupconfig.permissions.carddownload === "write"){
                            // console.log("Download: Allow download from permission")
                            setStyleDownload({visibility:"visible", opacity: "1"});
                    }
                    else{
                        // console.log("Download: no carddownload permission")
                        if((props.groupconfig.group ==='reviewgroup' )){ 
                            // console.log("Download: Allow for review group")
                            setStyleDownload({visibility:"hidden", opacity: "1"});            
                        }
                    }
                }            
            }
            //Disable the footer buttons if the driverid is set (this signals operator permissions are enabled)
            if(props.groupconfig && props.groupconfig.driverid){
                setShowButtons(false);
            }
        } catch (error) {
            console.log("Fail on dllink component:" ,error);
        }
        

    },[isAddingComment,downloadLink]) //listen for changes to the isAddingComment state, or download URL 

    //Handle changes to the adding comment button or download link
    React.useEffect(()=>{
        // console.log("Allowed: ",props.allowedButtons);
        if(!props.allowedButtons){return;}   

        if(!props.allowedButtons.includes('addcomment')){toggleButton_comment(false);}
        if(!props.allowedButtons.includes('download')){toggleButton_Download(false);}
        if(!props.allowedButtons.includes('delete')){toggleButton_Delete(false);}
        if(!props.allowedButtons.includes('highlights')){toggleButton_Highlight(false);}
        if(!props.allowedButtons.includes('irrelevant')){toggleButton_Irrelevant(false);}
        if(!props.allowedButtons.includes('videocut')){toggleButton_Cut(false);}
        

        // if(!rest.allowedButtons.includes('cut')){toggleButton_Cut(true);}

    },[props.allowedButtons]) //listen for changes to the isAddingComment state, or download URL 
    

    //Callbacks for the button clicks:
    //---------------------------------
    //Action to take when Downlaod is clicked
    function onDownloadClick(_event) {
        // console.log("Download clicked")
        if(isDownloaded){return;}
        setDownloaded(true); //set the state so that the notecard can only be downloaded once
        try {
            let filename = saveBlob(downloadLink.url,downloadLink.name,typeAndTagText);
            APILogger.addLog({type:'download-notecard',infractionid: props.infractionID, filename: filename}); //log the download request for to the audit tables
        } catch (error) { }
    }//end onDownloadClick

    //Action to take when GoTO is clicked
    function onVideoLinkClick(_event) {
        // console.log("VideoLink clicked");
        if(!props.eventNotify){console.log("eventNotify not defined"); return;}
        try {
            const realPromise = Auth.currentSession().then(
                (auth) => {
                    let apiName = "AuthLambda";
                    let path = "/listVideos";
                    let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        filter: props.filter,
                        id: linkedVidID,
                        flags: props.flags,
                    }
                    };
                    return API.post(apiName, path, myInit);
                });
            realPromise.then(data =>{
                // console.log("Returned the data to generate the link:" ,data);
                if(data.results && data.results[0]){
                    props.eventNotify({
                        type: 'link',
                        data: {
                            href: 'analytics',
                            extra: {
                                groupconfig: props.groupconfig,
                                video: data.results[0],
                                filter: props.filter,
                            }
                        },
                    });
                }else{
                    //Handle no return data
                    window.confirm("Highlights page is still building, please try again at a later time.",);
                }
                // 
            })
            realPromise.catch(function(error) {
                console.log("highlights link error: ",error);
            });
        } catch (error) {
            console.log("highlights link error: ",error);
        }
    }
    //Action to take when Irrelevant is clicked
    function onIrrelevantClick(_event) {
        // console.log("Click on Irrelevant",)
        if(isEditDisabled){return;}

        //Make sure we aren't trying to remove the Drowsiness tags        
        if(infractionTags.includes('Drowsiness')){
            // console.log("Trying to remove a restricted type: ",this.state.currentUsername,this.props )
            if(this.props.groupconfig && this.props.groupconfig.permissions && !this.props.groupconfig.permissions.drowsyremove){
                return;//block the removal of restricted tags
            }
        }
        
        if(onTagChange){onTagChange({value:'Irrelevant',confirmed:false});}



    }
    //Action to take when Cut is clicked
    function onCutClick(_event) {
        // console.log("Click on Cut",)
        if(isVideoCutDisabled){return;}
        toggleCutVideo(true);

    }
    //Action to take when delete is clicked
    function onDeleteClick(_event) {
        if(isEditDisabled){return;}
        if(isDeleteDisabled){return;}
        // console.log("Click on Delete card")
        if (window.confirm("Remove this notecard? Its contents will be permanently deleted.")) {
            if(props.cardChange){
                props.cardChange({
                    cardID: props.cardID,
                    delete: true,
                    tag: props.tag,
                    infractionID : props.infractionID,
                    infractionType:props.infractionType,
                });
            }
            if(props.handleClose){
                props.handleClose({
                    cardID: props.cardID,
                    delete: true,
                    tag: props.tag,
                    infractionID : props.infractionID,
                    infractionType: props.infractionType,
                    infractionTags:props.infractionTags,
                });
            }
                      
        }
        
    }

    //Add the callback to update a comment:
    function submitComment(_comment) { 
        // console.log("Adding comment");

        const newComment = isAddingComment && isAddingComment.trim();
        if (newComment && onUpdateComment) {
            onUpdateComment({text: newComment, action:'add'});
        } 
        
        //Release the new comment to close the TextArea
        setAddingComment(null);
        
    }


    

    //Button to render: 1. Add Comment, 2. Download, 3. Goto Highlights page, 4. Irrelevant, 5. Delete
    // The first button is rendered by <Commenter>, the remaining are standard buttons
    // console.log("Foot class:" ,rest,rest.className||"notecard-footer")
    return (
       <div className={rest.className||"notecard-footer"}>

            {!canShowComment || (props.groupconfig && props.groupconfig.driverid)?
                null :
                <Commenter comment={isAddingComment} //holds the current string being added
                        disableEdits={isCommentDisabled}
                        setComment={(_comment)=>{setAddingComment( _comment);}}
                        cancelComment={(_comment)=>{setAddingComment(null);}}
                        submitComment={submitComment}
                />
            }    
            {(canShowButtons &&canShowDownload)&& <StatefulButton className={isDownloaded?"btn downloaded-button":"btn download-button"} style={styleDownload} onClick={onDownloadClick }  > {isDownloaded?"Downloaded":"Download"} </StatefulButton>}
            {(canShowButtons &&canShowCut)&& <StatefulButton  className="btn cut-button" onClick={onCutClick } style={styleCut} disabled={isVideoCutDisabled} > Cut Video </StatefulButton>}    
            {(canShowButtons &&canShowHighlight)&& <StatefulButton  className="btn gotohighlight-button" onClick={onVideoLinkClick } disabled={(props.noEdit||props.noLink||!linkedVidID)} > Go To Highlights Page </StatefulButton>}
            {(canShowButtons &&canShowIrrelevant)&& <StatefulButton  className="btn irrelevant-button" onClick={onIrrelevantClick } style={styleIrrelevant} disabled={isEditDisabled} > Irrelevant </StatefulButton>}    
            {(canShowButtons &&canShowDelete) && <StatefulButton  className="btn btn-danger delete-button"  onClick={onDeleteClick } style={styleDelete} disabled={isEditDisabled||isDeleteDisabled} > Delete </StatefulButton>} 
            
            {canShowVideoCut&&  
                <CutVideoNotecard 
                    groupconfig = {props.groupconfig}
                    handleClose={()=>{toggleCutVideo(false);}}
                    details = {props}
                    eventNotify={props.eventNotify}
                />    
            }
                                    
        </div>
    )
}//end of the NCFooter component

/*
* @brief format the type name displayed on the notecard, prioritize naming using the tagged infractions when present
* @param __props: pass the properties object to extract the values passed to the class on creation
*/
export function getTypeAndTagText(_props) {
    // console.log("typeandtag: ",_props, _props.infractionTags,OTHERHWTAGS);
    let typeAndTag ="";
    if(_props.infractionTags && _props.groupconfig && _props.infractionTags.length>0){
        try {
            //Tag the infractions with their severities:
            let sortedInfraction = _props.infractionTags.map(elem=>{
                return _props.groupconfig.infractionTags.filter(s => s.type === elem)[0];
            });
            //Also look at the tagged OTHER HW Tags:
            const otherTags = _props.infractionTags.map(elem=>{
                return OTHERHWTAGS.filter(s => s.type === elem)[0];
            });
            
            sortedInfraction= sortedInfraction.concat(otherTags);
            // console.log("Other tags: ",otherTags,sortedInfraction);

            //Sort the tagged infractions based on severity
            sortedInfraction.sort((a, b) => {
                const sortBy = (x) => (SEVERITIES.indexOf(x));
                if (sortBy(a.severity) < sortBy(b.severity)) {
                    return -1;
                }
                if (sortBy(a.severity) > sortBy(b.severity)) {
                    return 1;
                }
                return 0;
            });

            //choose the name to include the infraction tag with the highest severity
            try{
                // console.log("Sorted: ",sortedInfraction[0],sortedInfraction)
                typeAndTag = sortedInfraction[0].type;
            }catch(e){
                // console.log("Unable to read: ",sortedInfraction);
                typeAndTag ="";
            }
        } catch (error) {
            console.log("Fail to sort: ",error);
        }
        
        
        
    }else{ // No infraction tags found: use the detected base type for the name
        typeAndTag = displayInfraction(_props.displayNameSet,_props.infractionType);
        if(_props.groupconfig){
            typeAndTag = displayInfraction(_props.groupconfig.displayNameSet,_props.infractionType);
        }
        typeAndTag = null;
    }
    if (typeAndTag && _props.tag) {
        typeAndTag += " - " + _props.tag;
    }

    return typeAndTag;
}



