///////////////////////////////////////////////////////
// Imports and constants
///////////////////////////////////////////////////////
import React, { PureComponent } from 'react';
import './StaffPerformance.css'; // Link to the CSS Style sheet
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { CallinFiltersView } from '../Filters/CallinFilter.js';
import * as moment from 'moment';
import { DateFilterCheckboxes, FalsePositiveChart, CallErrorsChart, TotalCallsChart, CallLatencyChart, ComparisonCheckbox } from './StaffPerformanceCharts.js'
import { Auth, API } from 'aws-amplify';
import { ApiCaller, Spinner } from '../ApiCaller.js';
import BootstrapTable from 'react-bootstrap-table-next';
import { AverageReviewTime } from './Chart_AverageReviewTime.js';
import {isPermissionSet} from '../Util-access.js'

import {WrappedTable} from './WrappedTable.js';
import {enumerateDays,enumerateWeeks,enumerateMonths,StaffPerformanceMessages} from './chart_util.js'

import { withLoadingAnimation } from '../Wrappers/withLoadingAnimation.js';
import {SummaryShift} from './Summary_Shift.js';
const SummaryShift_loading = withLoadingAnimation(SummaryShift); //
// const SummaryShift_Memo = React.memo(SummaryShift_loading);



const MIN_DATE = moment("05-01-2023","MM-DD-YYYY");

///////////////////////////////////////////////////////
// Style Performance View
///////////////////////////////////////////////////////
/*
* @brief Online implementation of the Beirut team performance report
*/
class StaffPerformanceView extends PureComponent {
    /*
    * @brief First react lifecycle method called 
    */
    constructor(props) {
        super(props);

        // Bind all the functions
        this.onTabSelected = this.onTabSelected.bind(this);
        this.onBoxSelected = this.onBoxSelected.bind(this);
        this.onFilterSelected = this.onFilterSelected.bind(this);
        this.applyFilters = this.applyFilters.bind(this);
        this.updateFilters = this.updateFilters.bind(this);
        this.updateData = this.updateData.bind(this);
        this.getApiCall = this.getApiCall.bind(this);
        this.fetchFalsePositiveData = this.fetchFalsePositiveData.bind(this);
        this.fetchTotalCallsData = this.fetchTotalCallsData.bind(this);
        this.fetchCallErrorData = this.fetchCallErrorData.bind(this);
        this.fetchCallLatencyData = this.fetchCallLatencyData.bind(this);
        this.getXAxisData = this.getXAxisData.bind(this);
        this.truncateDate = this.truncateDate.bind(this);

        // Enumerate the days for the empty axes
        let begin = this.truncateDate(moment().startOf('month').subtract(3,'months'));
        let begin_shift = moment().subtract(3,'days');
        let end =  this.truncateDate(moment().startOf('month').subtract(1,'days'));
        let xdata = enumerateWeeks(begin,end);

        this.onDataChange = this.onDataChange.bind(this);

        // Get the sites
        let sites = [];
        for( const site of this.props.possibleFilters.gpsSites) {
            if(site.alias.toLowerCase() !== 'redeployable') {sites.push(site.alias);}
        }
        
        // Define the state
        this.state = {
            retryCount: 0,                              // flag to redo the API queries
            activeView: 'stats',                        // active tab view
            // activeView: 'shift',                        // active tab view
            tabViews: [ {key:'stats', name:'Staff Ranking'},{key:'resp', name:'Responsiveness'}, {key:'acc', name:'Accuracy'}], //list of tab views
            startDate: begin,                           // date filter start
            endDate: end,                               // date filter end
            startDate_shift:begin_shift,                           // date filter start
            endDate_shift: null,                               // date filter end
            filterUpdateTime: new Date(),               // time filter last modified
            sitelist: sites,                            // list of sites
            stafflist: null,                            // list of staff members
            secondaryAxis: false,                       // secondary axis flag
        
            // False positive data to plot
            loadedFPData : false,
            fpData: {                                   
                xdata : xdata,
                xdatasecondary : null,
                fp : new Array(xdata.length).fill(null),
                fn : new Array(xdata.length).fill(null),
                sdfp : new Array(xdata.length).fill(null),
                sdfn : new Array(xdata.length).fill(null),
                total : new Array(xdata.length).fill(null),
            },
            fpRanking: [],  
            sdRanking: [],    
            
            // Call error data to plot
            loadedErrorData : false,
            errorData: {                                 
                xdata : xdata,
                xdatasecondary : null,
                nocall : new Array(xdata.length).fill(null),
                latecall : new Array(xdata.length).fill(null),
                noescalate : new Array(xdata.length).fill(null),
                total : new Array(xdata.length).fill(null),
            },
            errorRanking: [],       

            // Total calls data to plot
            loadedCallData : false,
            callData: {                                 
                xdata : xdata,
                xdatasecondary : null,
                series : new Array(sites.length).fill(null).map(()=>new Array(xdata.length).fill(null)),
                total : new Array(xdata.length).fill(null),
            }, 

            // Call latency to plot
            loadedLatencyData : false,                  
            latencyData: {
                xdata : xdata,
                xdatasecondary : null,  
                boxplot : new Array(xdata.length).fill(null).map(()=>new Array(5).fill(null)),
            },
            latencyRanking: [],
            
            xmin : 0,                                   // common axis boundaries                             
            xmax : xdata.length -1,
            smin: null,
            smax: null,

            filters: {},                                // available dropdowns menus
            filters_shift: {},                                // available dropdowns menus
            
            selectedFilters: {                          // values selected in the dropdowns
                startDate: begin,
                endDate: end,
                bin: 'Week',
            },
            selectedFilters_shift: {                          // values selected in the dropdowns
                startDate: begin_shift,
                endDate: null,
                bin: 'Week',
            },

            activeFilters: {                            // applied filter settings
                startDate: begin,
                endDate: end,
                bin: 'Week',
            },
            activeFilters_shift: {                            // applied filter settings
                startDate: begin_shift,
                endDate: null,
                bin: 'Week',
            },
            

            filterKeys : {                              // label and order of each filter after the date
                'e3person': {     
                    'defaultVal': 'All',       
                    'title': 'Staff Member',
                    order:2,
                    disabled: false,
                },
                'bin': {
                    'defaultValue': 'Week',
                    'title': 'Bin',
                    order: 3,
                    disabled: false,
                }
            },

            unfulfilledPromises: [],                    // promises yet to return

            boxStates: {                                // checkbox states
                checkedThisQuarter: true,
                checkedThisMonth: false,
                checkedLastMonth: false,
                checkedThisYear: false,
            },
            boxStates_shift: {                                // checkbox states
                checkedThisQuarter: false,
                checkedThisMonth: false,
                checkedLastMonth: false,
                checkedThisYear: false,
            },

            renderedCharts: null
        };


        if(isPermissionSet(this.props.groupconfig,"staffperformance",{shift:true})){
            this.state.tabViews.push({key:'shift', name:'Dedicated Time'})            
        } //additional detailed permission set     

    } //end constructor

    /*
    * @brief React lifecycle method called after rendering
    */
    componentDidMount() {
        // Run a one time query to get the current users
        Auth.currentSession().then(
            (auth) => {
                let apiName = "UserManagement";
                let path = "/listAllCurrentUsers";
                let myInit = {
                    body: {
                        GroupName: this.props.groupconfig.group,                
                        Groups: (this.props.userInfo.allowedGroups && this.props.userInfo.allowedGroups.length>0)?this.props.userInfo.allowedGroups.join(','):null,
                        Sites: null,
                    }
                };
               return API.post(apiName, path, myInit);
            })
            // Log any errors
            .catch((error) => {
                console.error("UserManagent api call error ",error); 
            // Run on authorization return
            }).then((data) => {       
                try {
                    // console.log("Users: ",data);
                    if(data.users){
                        // Define an exclusion list
                        let exclude = ['beirut_user','beirut_admin','beirut_pc1','beirut_pc2','beirut_pc3','dev_user','dev_tech'];
    
                        // Build a list of active users
                        let stafflist = [];
                        let staffDetails = [];
                        for (const staff of data.users) {
                            // Is active user
                            if(staff.Enabled && !exclude.includes(staff.Username)) {
                                // Define local matching count
                                let count = 0;
    
                                // Iterate over the attributes
                                let staffname = null;
                                for (const attrib of staff.Attributes) {
                                    // Does the email match
                                    if(attrib.Name === 'email'){
                                        if(attrib.Value.includes('@simulator.amazonses.com')) {
                                            count = count +1;
                                        }
                                    // Does the role match
                                    } else if(attrib.Name === 'custom:Role') {
                                        if(attrib.Value.includes('Analyst')) {
                                            count = count +1;
                                        }
                                    }                           
                                    if(attrib.Name === 'name'){    
                                        staffname = attrib.Value;
                                    }
                                }
    
                                // Did all criteria match
                                if(count > 1) {
                                    stafflist.push(staff.Username);
                                    staffDetails.push({
                                        username:staff.Username,
                                        name: staffname
                                    });
                                }
                            }
                        }

                        // Pass on the sorted staff list
                        // console.log("Staff Details: ",staffDetails);
                        this.setState({stafflist: Array.from(new Set(stafflist.sort())), staffDetails:staffDetails});
                        this.updateFilters(this.state.activeView);
                    } else {
                        console.log('No user data returned')
                    }
                } catch(e) {
                    console.error("UserManagent api call error ",e); 
                }    
            });   
    } //end compenentDidMount

    /*
    * @brief React lifecycle method called removing elements from the DOM
    */
    componentWillUnmount() {
        // Loop over all current promises
        const promises = this.state.unfulfilledPromises;
        promises.forEach(promise_=>{
            // Attempt to cancel the promise
            if(API.cancel(promise_, "Component unmounted, abort current queries")){ 
                // Remove the canceled promise from the unfulfilled list
                this.setState(prevState => {          
                    const promises = prevState.unfulfilledPromises; 
                    const index = (promises||[]).indexOf(promise_);
                    if (index > -1) { // only splice array when item is found
                        promises.splice(index, 1);
                    }
                    return{unfulfilledPromises:promises}
                });  
            }
        });
    } //end componentWillUnmount

    /*
    * @brief Method called when a tab is selected
    */
    onTabSelected(_name) {
        // Update the filters
        this.updateFilters(_name);
        // 
        if(_name === 'shift'){
            this.setState({activeView:_name});
            return;
        }
        // Update the checkboxes and state on the response tab
        if(_name !== "resp" && !this.state.boxStates.checkedThisQuarter
            && (this.state.boxStates.checkedLastMonth || this.state.boxStates.checkedThisMonth || !this.state.boxStates.checkedThisYear)) {
            console.log("Udpate boxes on tab change ",_name);
            // Notify the dropdowns of filter selection
            this.onBoxSelected('on',{
                startDate: moment().startOf('month').subtract(3,'months'), // shift the start date 3 months
                endDate: moment().startOf('month').subtract(1,'days'),     // shift the end date to end of previous month
                checkedThisQuarter: true,
                checkedLastMonth: false,
                checkedThisMonth: false,
                checkedThisYear: false,
                activeView:_name,
            });
        // Otherwise, just update the state
        } else {
            this.setState({activeView:_name})
        }
    }

    /*
    * @brief Method called when a box is selected
    */
    onBoxSelected(_name, _value) {
        let newState = {};
        // console.log("Box select",this.state.activeView ,_name,_value);
        // Which case has been updated
        switch(_name) {
            case 'on': {
                // Read startdate, enddate, and bin
                let startDate = _value.startDate ? moment.max(_value.startDate,MIN_DATE) : null;   
                let endDate = _value.endDate ? moment.max(_value.endDate,MIN_DATE) : null;  

                // Update the associated state variables
                
                if(this.state.activeView==='shift'){
                    newState = {
                        startDate_shift: startDate,
                        endDate_shift: endDate,
                        selectedFilters_shift: Object.assign(this.state.selectedFilters_shift,{startDate: startDate, endDate: endDate, ...(_value.bin && {bin: _value.bin})}),
                        filterUpdateTime: new Date(),
                        ...(_value.hasOwnProperty('activeView') && {activeView: _value.activeView}),
                        
                        
                    }
                    newState.boxStates_shift=  Object.assign(this.state.boxStates_shift, {                                                        
                        ...(_value.hasOwnProperty('checkedThisQuarter') && {checkedThisQuarter: _value.checkedThisQuarter}),
                        ...(_value.hasOwnProperty('checkedThisMonth') && {checkedThisMonth: _value.checkedThisMonth}),
                        ...(_value.hasOwnProperty('checkedLastMonth') && {checkedLastMonth: _value.checkedLastMonth}),
                        ...(_value.hasOwnProperty('checkedThisYear') && {checkedThisYear: _value.checkedThisYear}),  
                    })
                }else{
                    newState = {
                        startDate: startDate,
                        endDate: endDate,
                        selectedFilters: Object.assign(this.state.selectedFilters,{startDate: startDate, endDate: endDate, ...(_value.bin && {bin: _value.bin})}),
                        filterUpdateTime: new Date(),
                        ...(_value.hasOwnProperty('activeView') && {activeView: _value.activeView}),
                        
                        
                    }
                    newState.boxStates=  Object.assign(this.state.boxStates, {                                                        
                        ...(_value.hasOwnProperty('checkedThisQuarter') && {checkedThisQuarter: _value.checkedThisQuarter}),
                        ...(_value.hasOwnProperty('checkedThisMonth') && {checkedThisMonth: _value.checkedThisMonth}),
                        ...(_value.hasOwnProperty('checkedLastMonth') && {checkedLastMonth: _value.checkedLastMonth}),
                        ...(_value.hasOwnProperty('checkedThisYear') && {checkedThisYear: _value.checkedThisYear}),  
                    })
                }
            } break;

            case 'off':
                // Update the associated state variables
                if(this.state.activeView==='shift'){
                    newState = {
                        boxStates_shift:  Object.assign(this.state.boxStates_shift, {                            
                            ...(_value.hasOwnProperty('checkedThisQuarter') && {checkedThisQuarter: _value.checkedThisQuarter}),
                            ...(_value.hasOwnProperty('checkedThisMonth') && {checkedThisMonth: _value.checkedThisMonth}),
                            ...(_value.hasOwnProperty('checkedLastMonth') && {checkedLastMonth: _value.checkedLastMonth}),
                            ...(_value.hasOwnProperty('checkedThisYear') && {checkedThisYear: _value.checkedThisYear}), 
                        }),
                    }
                }else{
                    newState = {
                        boxStates:  Object.assign(this.state.boxStates, {                            
                            ...(_value.hasOwnProperty('checkedThisQuarter') && {checkedThisQuarter: _value.checkedThisQuarter}),
                            ...(_value.hasOwnProperty('checkedThisMonth') && {checkedThisMonth: _value.checkedThisMonth}),
                            ...(_value.hasOwnProperty('checkedLastMonth') && {checkedLastMonth: _value.checkedLastMonth}),
                            ...(_value.hasOwnProperty('checkedThisYear') && {checkedThisYear: _value.checkedThisYear}), 
                        }),
                    }
                }

                
            break;

            case 'comparison':
                // Update the associated state variables
                newState = {
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    selectedFilters: Object.assign(this.state.selectedFilters,{startDate: this.state.startDate, endDate: this.state.endDate, 
                        ...(_value.e3person && {e3person: _value.e3person})}),
                    filterUpdateTime: new Date(),
                    secondaryAxis: _value.secondaryAxis, 
                }
            break;

            default:
            break;
        }
        
        // Propagate if new
        if(newState) { 
            // console.log("ON box new state: ",newState);
            this.setState(newState, () => {
                // Update the view to hide the staff filter
                this.updateFilters(this.state.activeView);
                this.applyFilters();
            })  
        };
    }

    /*
    * @brief Method called when a filter is selected
    */
    onFilterSelected(_name, _value) {
        let newState = {};
        // console.log("On Filters: ",this.state.activeView,_name, _value);

        // Which case has been updated
        switch(_name) {
            case 'endDate':
            case 'startDate':{
                return;
            }break;
            case 'date': {
                if(this.state.activeView==='shift'){
                    newState = {filterUpdateTime: new Date(), startDate_shift:null, endDate_shift: null};
                    let dates = _value.value;
                    if(dates.start){
                        let tempDate = dates.start ? moment.max(dates.start,MIN_DATE) : null;
                        newState.startDate_shift = tempDate;                         
                        // console.log("Set the startDate: ",tempDate.format('YYYY-MM-DD'),newState.startDate.format('YYYY-MM-DD'));
                    }
                    if(dates.end){
                        let tempDate = dates.end ? moment.max(dates.end,MIN_DATE) : null;
                        newState.endDate_shift = (tempDate && this.state.startDate_shift) ? moment.max(tempDate, this.state.startDate_shift) : tempDate;                         
                    }
                    //Udpate the selected filters
                    newState.selectedFilters_shift= Object.assign(this.state.selectedFilters_shift,{startDate: newState.startDate_shift, endDate: newState.endDate_shift});
                    //Reset checkboxes
                    if(dates.action && dates.action=='click'){
                        newState.boxStates_shift=  {                                // checkbox states
                            checkedThisQuarter: false,
                            checkedThisMonth: false,
                            checkedLastMonth: false,
                            checkedThisYear: false
                        }
                    }    
                }else{
                    newState = {filterUpdateTime: new Date(), startDate:null, endDate: null};
                    let dates = _value.value;
                    if(dates.start){
                        let tempDate = dates.start ? moment.max(dates.start,MIN_DATE) : null;
                        newState.startDate = tempDate;                         
                        // console.log("Set the startDate: ",tempDate.format('YYYY-MM-DD'),newState.startDate.format('YYYY-MM-DD'));
                    }

                    if(dates.end){
                        let tempDate = dates.end ? moment.max(dates.end,MIN_DATE) : null;
                        newState.endDate = (tempDate && this.state.startDate) ? moment.max(tempDate, this.state.startDate) : tempDate;                         
                    }
                    //Udpate the selected filters
                    newState.selectedFilters= Object.assign(this.state.selectedFilters,{startDate: newState.startDate, endDate: newState.endDate});
                    //Reset checkboxes
                    if(dates.action && dates.action=='click'){
                        newState.boxStates=  {                                // checkbox states
                            checkedThisQuarter: false,
                            checkedThisMonth: false,
                            checkedLastMonth: false,
                            checkedThisYear: false
                        }
                    }
                }
                
            }break;
            case 'e3person':{
                if(this.state.activeView==='shift'){
                    // Update the selected filter with the name/value pair
                    let newFilter = {};
                    newFilter.e3person=_value.value;
                    newState.selectedFilters_shift = Object.assign(this.state.selectedFilters_shift,newFilter);
                    // console.log("New filter: ",newFilter,newState);
                }
                else{
                    // Update the selected filter with the name/value pair
                    let newFilter = {};
                    newFilter.e3person=_value.value;
                    newState.selectedFilters = Object.assign(this.state.selectedFilters,newFilter);
                }
            }break;

            default: {
                // Add the name/value pair to the new State
                newState['filterUpdateTime']= new Date();
                if(this.state.activeView==='shift'){
                    newState['startDate']= this.state.startDate_shift;
                    newState['endDate']= this.state.endDate_shift;
                    // Update the selected filter with the name/value pair
                    let newFilter = {};
                    newFilter[_name]=_value.value;
                    newState.selectedFilters_shift = Object.assign(this.state.selectedFilters_shift,newFilter);
                }
                else{
                    newState['startDate']= this.state.startDate;
                    newState['endDate']= this.state.endDate;
                    // Update the selected filter with the name/value pair
                    let newFilter = {};
                    newFilter[_name]=_value.value;
                    newState.selectedFilters = Object.assign(this.state.selectedFilters,newFilter);
                }
                

                
            } break;
        }
        
        // Propagate if new
        if(newState) { 
            // console.log("Clear the shiftData on filter change")
            // newState.shiftData= null;
            // console.log("Push new selected: ",newState);

            this.setState(newState, () => {
                // Update the view to hide the staff filter
                this.updateFilters(this.state.activeView);
            })  
        };
    } //end onFilterSelected
   
    /*
    * @brief Method drop the time from a date
    */
    truncateDate(_date) {
        return moment.utc(_date.startOf('day').format("YYYY-MM-DD"),"YYYY-MM-DD")
    }
    
    /*
    * @brief Method called when a filter is applied
    */
    applyFilters() {
      

        // Copy the selected filters to the appliedFilters
        let newState = null;
        if(this.state.activeView==='shift'){
            newState = {
                startDate_shift: this.state.selectedFilters_shift.startDate,
                endDate_shift: this.state.selectedFilters_shift.endDate,
                activeFilters_shift: {
                    ...(this.state.selectedFilters_shift.startDate && {startDate: this.truncateDate(this.state.selectedFilters_shift.startDate)}),
                    ...(this.state.selectedFilters_shift.endDate && {endDate: this.truncateDate(this.state.selectedFilters_shift.endDate)}),
                    ...(this.state.selectedFilters_shift.e3person && {e3person: this.state.selectedFilters_shift.e3person}),
                },
            }
        }else{
              // Clear the loaded state
                this.setState({
                    loadedCallData: false,
                    loadedFPData: false,
                    loadedErrorData: false,
                    loadedLatencyData: false
                }); 

                // Loop over all the unfufilled promises
                const promises = this.state.unfulfilledPromises;
                promises.forEach(promise_=>{
                    // Cancel the promise because the underlying query is no longer valid
                    if(API.cancel(promise_, "Filters updated, abort current queries")) { 
                        // Once the promise is canceled, remove it from the unfulfilled list
                        this.setState(prevState => {          
                            const promises = prevState.unfulfilledPromises; 
                            const index = (promises||[]).indexOf(promise_);
                            if (index > -1) { // only splice array when item is found
                                promises.splice(index, 1);
                            }
                            return{unfulfilledPromises:promises}
                        });  
                    }
                });

            newState = {
                startDate: this.state.selectedFilters.startDate,
                endDate: this.state.selectedFilters.endDate,
                activeFilters: {
                    ...(this.state.selectedFilters.startDate && {startDate: this.truncateDate(this.state.selectedFilters.startDate)}),
                    ...(this.state.selectedFilters.endDate && {endDate: this.truncateDate(this.state.selectedFilters.endDate)}),
                    ...(this.state.selectedFilters.e3person && {e3person: this.state.selectedFilters.e3person}),
                    ...(this.state.selectedFilters.bin && {bin: this.state.selectedFilters.bin})
                },
                retryCount: this.state.retryCount+1, //increment the retry count
            }
        }
        
        if(newState){
            // console.log("Appy filter:",this.state.activeView ,newState);
            // Update the state
            this.setState(newState);    
        }
        
    } //end applyFilters

    /*
    * @brief Method called when query returns with new filter lists
    */
    updateFilters(_view) {
        // console.log("Updatefilters")
        // Are we on the Responsiveness tab
        let allowedViews = ['resp','shift'];
        let newState = null;
        // let bResponse = allowedViews.includes(_view);
        if(_view === 'resp'){
            let filters = {                                  // available dropdowns menus
                ...( {bin: new Set(["Week","Month"])}),
                ...( !this.state.secondaryAxis && {e3person: new Set(this.state.stafflist)}),
                ...( this.state.secondaryAxis && {e3person: new Set(null)}),
            }
            // Update the filters with the list of staff members from the API call
            newState= {
                filters: filters,
            };
        }
        else if(_view ==='shift'){
            let filters = {                                  // available dropdowns menus
                ...( !this.state.secondaryAxis && {e3person: new Set(this.state.stafflist)}),
            }
            // Update the filters with the list of staff members from the API call
            newState= {
                filters_shift: filters,
            };
        }

        
        
        if(newState){
            newState.filterUpdateTime = new Date();
            // Propagate the new state
            this.setState(newState); 
        }
        
    } //end updateFilters

    /*
    * @brief Initial API call to get filter dropdowns 
    */
    getApiCall() {
        // console.log("Get API called?",new Date());
        // Get all data
        this.fetchCallErrorData();
        this.fetchTotalCallsData();
        this.fetchCallLatencyData();
        this.fetchFalsePositiveData();

        //Message subscribers:
        // StaffPerformanceMessages.broadCast({clip:_clip, assetid: _clip.assetid});

        return Promise.resolve();
    }

    /*
    * @brief Takes care of updating the filters given the completed API queries
    */
    updateData(data) {}

    /**
     * @brief Helper function to generate the x-axis data
     */
    getXAxisData(_startDate, _endDate, _staff, _bin, _bSecondary, _stafflist) {
        // Make sure the start and end date are defined
        let endDate = _endDate == null ? moment().startOf('day') : _endDate;
        let startDate = _startDate == null ? moment('2022-07-01') : _startDate;

        // Get the x axis
        if(_bSecondary) {
            let primary = [];
            let secondary = [];

            // Switch between the bins
            switch(_bin) {
                case 'Staff':
                    return {xdata: _stafflist, xdatasecondary: null};
                case 'Day':
                    // The primary axis is the Staff and the secondary axis is the Week
                    secondary = enumerateDays(startDate, endDate);
                    secondary.forEach(() => primary.push(..._stafflist));
                    return {xdata : primary, xdatasecondary : secondary};  
                case 'Month':
                    // The primary axis is the Staff and the secondary axis is the Week
                    secondary = enumerateMonths(startDate, endDate);
                    secondary.forEach(() => primary.push(..._stafflist));
                    return {xdata : primary, xdatasecondary : secondary};  
                default:
                case 'Week':
                    // The primary axis is the Staff and the secondary axis is the Week
                    secondary = enumerateWeeks(startDate, endDate);
                    secondary.forEach(() => primary.push(..._stafflist));
                    return {xdata : primary, xdatasecondary : secondary};   
            }// end switch
        } else {
            // Switch between the bins
            switch(_bin) {
                case 'Staff':
                    return {xdata: _stafflist, xdatasecondary: null};
                case 'Day':
                    return {xdata : enumerateDays(startDate, endDate), xdatasecondary: null};
                case 'Month':
                    return {xdata : enumerateMonths(startDate, endDate), xdatasecondary: null};
                default:
                case 'Week':
                    return {xdata : enumerateWeeks(startDate, endDate), xdatasecondary: null};
            }// end switch
        }

    }; //end getXAxisData

    /*
    * @brief Helper method to fetch the false positive and negative data
    */
    fetchFalsePositiveData() {
        // Define the API lambda to launch
        let authPromise = Auth.currentSession();
        authPromise.then((auth) => {                 
            let apiName = "TrifectaAPI";
            let path = "/handleStaffPerformance";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    mode:'falsepositive',  
                    activeFilters: {
                        startDate: this.state.activeFilters.startDate,
                        endDate: this.state.activeFilters.endDate,
                        e3person: 'All',
                        bin: 'Staff'
                    },
                    secondaryAxis: this.state.secondaryAxis,
                    clientid: this.props.groupconfig.group,
                }
            };

            try {            
                // Launch the API request
                const apiPromise = API.post(apiName, path, myInit)

                // Record the promise in the list of waiting to complete
                this.setState(prevState => {
                    const promises = prevState.unfulfilledPromises;
                    promises.push(apiPromise);
                    return{unfulfilledPromises:promises}
                });  
        
                // On promise completion, do the following:
                apiPromise.then( _data =>{
                    try {
                        // Remove the fulfilled promise from the list
                        this.setState(prevState => {
                        const promises = prevState.unfulfilledPromises;
                        const index = (promises||[]).indexOf(apiPromise);
                        if (index > -1) { // only splice array when item is found
                            promises.splice(index, 1);
                        }
                        return{unfulfilledPromises:promises}
                        });  
                    } catch (error) {
                        console.log("Then error, fetchFalsePositiveData: ",error);
                    }
            
                    try {
                        // Is the return valid
                        if(_data.error === false) {
                            // Generate the xaxis data
                            let {xdata,xdatasecondary} = this.getXAxisData(this.state.startDate, this.state.endDate, 
                                'All', 'Staff', false, this.state.stafflist);

                            // Define empty arrays
                            let fp = new Array(xdata.length).fill(Number(0));
                            let fn = new Array(xdata.length).fill(Number(0));
                            let sdfp = new Array(xdata.length).fill(Number(0));
                            let sdfn = new Array(xdata.length).fill(Number(0));
                            let total = new Array(xdata.length).fill(Number(0));

                            // Loop over each row
                            for( const row of _data.response ) {
                                // Get the xdata and xdatasecondary without spaces
                                let primary = row.xdata.replace(/ +(?= )/g,'');
                                let secondary = row.xdatasecondary ? row.xdatasecondary.replace(/ +(?= )/g,'') : null;

                                // Get the index of the corresponding entry on the xaxis
                                let index = xdatasecondary ? xdata.indexOf(primary,xdatasecondary.indexOf(secondary)*this.state.stafflist.length) : xdata.indexOf(primary);

                                // Copy the SQL data to the index
                                if(index >=0) {
                                    fp[index] = Number(row.fp);
                                    fn[index] = Number(row.fn);
                                    sdfp[index] = Number(row.sdfp);
                                    sdfn[index] = Number(row.sdfn);

                                    // Sum the entries for easier data labels
                                    total[index] = fp[index]+fn[index]+sdfp[index]+sdfn[index];
                                }
                            }

                            // Loop over each row of the ranking
                            let fpRanking = [];
                            for (const row of _data.rankTotal) {
                                // Is this staffer in the array
                                if(this.state.stafflist.indexOf(row.reviewer) > -1) {
                                    fpRanking.push({
                                        rank: fpRanking.length+1,
                                        staff: row.reviewer,
                                        total: row.total
                                    });
                                }
                            }
                            let sdRanking = [];
                            for (const row of _data.rankSevereDrowsiness) {
                                // Is this staffer in the array
                                if(this.state.stafflist.indexOf(row.reviewer) > -1) {
                                    sdRanking.push({
                                        rank: sdRanking.length+1,
                                        staff: row.reviewer,
                                        total: row.total
                                    });
                                }                                
                            }

                            // Update the state
                            let newData = {xdata : xdata, xdatasecondary : xdatasecondary, fp: fp, fn : fn, sdfp : sdfp, sdfn : sdfn, total: total};
                            this.setState({loadedFPData: true, fpData : newData, fpRanking: fpRanking, sdRanking: sdRanking});
                        }
                    } catch (error) { 
                        console.log("Data error, fetchFalsePositiveData: ",error);
                    }
                });//end then processing on promise

                // On API error, do the following:
                apiPromise.catch(err => {
                    // ARe we cancelling the promise
                    if (API.isCancel(err)) {
                    // Log the API request has been cancelled
                    console.log("API canceled, fetchFalsePositiveData: ", err.message); // "my message for cancellation"
                    } else{
                    console.log("API promise error, fetchFalsePositiveData: ",err)
                    }
                    //return;
                });
            } catch (error) {
                console.log("error: ",error);
            }      
        })

        // On Authorization error, do the following:
        authPromise.catch(err=>{
          console.log("Auth error, fetchFalsePositiveData:",err)
        });//end the auth return
    } //end fetchFalsePositiveData

    /*
    * @brief Helper method to fetch the call error data
    */
    fetchCallErrorData() {
        // Define the API lambda to launch
        let authPromise = Auth.currentSession();
        authPromise.then((auth) => {      
            let apiName = "TrifectaAPI";
            let path = "/handleStaffPerformance";
            let myInit = {
                body: {
                    token: auth.idToken.jwtToken,
                    mode:'callerrors',  
                    activeFilters: this.state.activeFilters,
                    secondaryAxis: this.state.secondaryAxis,
                    clientid: this.props.groupconfig.group,
                }
            };

            try {
                // Launch the API request
                const apiPromise = API.post(apiName, path, myInit)

                // Record the promise in the list of waiting to complete
                this.setState(prevState => {
                    const promises = prevState.unfulfilledPromises;
                    promises.push(apiPromise);
                    return{unfulfilledPromises:promises}
                });  
        
                // On promise completion, do the following:
                apiPromise.then( _data =>{
                    try {                        
                        // Remove the fulfilled promise from the list
                        this.setState(prevState => {
                        const promises = prevState.unfulfilledPromises;
                        const index = (promises||[]).indexOf(apiPromise);
                        if (index > -1) { // only splice array when item is found
                            promises.splice(index, 1);
                        }
                        return{unfulfilledPromises:promises}
                        });  
                    } catch (error) {
                        console.log("Then error, fetchCallErrorData: ",error);
                    }
            
                    try {
                        // Is the return valid
                        if(_data.error === false) {
                            // Generate the xaxis data
                            let {xdata,xdatasecondary} = this.getXAxisData(this.state.startDate, this.state.endDate, 
                                this.state.activeFilters.e3person, this.state.activeFilters.bin, this.state.secondaryAxis, this.state.stafflist);
                            // Define empty arrays
                            let nocall = new Array(xdata.length).fill(Number(0));
                            let latecall = new Array(xdata.length).fill(Number(0));
                            let noescalate = new Array(xdata.length).fill(Number(0));
                            let total = new Array(xdata.length).fill(Number(0));

                            // Loop over each row
                            for( const row of _data.response ) {
                                // Get the xdata and xdatasecondary without spaces
                                let primary = row.xdata.replace(/ +(?= )/g,'');
                                let secondary = row.xdatasecondary ? row.xdatasecondary.replace(/ +(?= )/g,'') : null;

                                // Get the index of the corresponding entry on the xaxis
                                let index = xdatasecondary ? xdata.indexOf(primary,xdatasecondary.indexOf(secondary)*this.state.stafflist.length) : xdata.indexOf(primary);
                                // Copy the SQL data to the index
                                if(index >=0) {
                                    nocall[index] = Number(row.nocall);
                                    latecall[index] = Number(row.latecall);
                                    noescalate[index] = Number(row.noescalate);

                                    // Sum the entries for easier data labels
                                    total[index] = nocall[index]+latecall[index]+noescalate[index];
                                }
                            }


                            let errorRanking = [];
                            for (const row of _data.rank) {
                                // Is this staffer in the array
                                if(this.state.stafflist.indexOf(row.reviewer) > -1) {
                                    errorRanking.push({
                                        rank: errorRanking.length+1,
                                        staff: row.reviewer,
                                        total: row.total
                                    });
                                }
                            }

                            // Determine the autoscale of the x axis
                            let xmax = 0;
                            let xmin = total.length;
                            let smin = null;
                            let smax = null;
                            for( const index in total ) {
                                // Find the index of the first data
                                if(total[index] > 0) {xmin = Math.min(index,xmin);}
                                // Find the index of the last data
                                if(total[index] > 0) {xmax = Math.max(index,xmax);}
                            }
                            xmax = Math.min(total.length,xmax+1);

                            // Do not trim staff filtered data
                            if(this.state.activeFilters.bin === 'Staff') {
                                xmax = total.length
                                xmin = 0;
                            // Account for the secondary axis
                            } else if(xdatasecondary != null) {      
                                xmax = total.length //Reset the xmax to allow the x-axis to display the full date range
                                xmin = 0;                        
                                // Shift the start and stop indices to the beginning and end of the staff list
                                smin = Math.floor(xmin/this.state.stafflist.length);
                                xmin = this.state.stafflist.length*smin;
                                smax = Math.max(1,Math.ceil(xmax/this.state.stafflist.length));
                                xmax = this.state.stafflist.length*smax;

                                // Allow no more than 8 bins
                                while ( (smax-smin) > 8) {
                                    smin = smin + 1;
                                    xmin = xmin + this.state.stafflist.length;
                                }
                            // Otherwise, adjust the start and stop index based on the bin
                            } else {
                                let incrementIndex = 0;
                                // If we have less than 5 bins, and can shift the start and stop, do so
                                while ( (xmax - xmin) < 5 && (xmax < total.length || xmin > 0) ) {
                                    if(incrementIndex % 2 === 0 || xmin === 0) {
                                        xmax = xmax +1;
                                        incrementIndex = incrementIndex + 1;
                                    } else {
                                        xmin = xmin - 1;
                                    }
                                }
                            }       

                            // Update the state, retrieve the false positive data after setting the xmin and xmax
                            let newData = {xdata : xdata, xdatasecondary : xdatasecondary, nocall: nocall, latecall : latecall, noescalate : noescalate, total: total};
                            this.setState({loadedErrorData: true, errorData : newData,
                                 xmin: xmin, xmax: xmax, smin: smin, smax: smax,
                                 errorRanking: errorRanking});
                        }
                    } catch (error) { 
                        console.log("Data error, fetchCallErrorData: ",error);
                    }
                });//end then processing on promise

                // On API error, do the following:
                apiPromise.catch(err => {
                    // ARe we cancelling the promise
                    if (API.isCancel(err)) {
                    // Log the API request has been cancelled
                    console.log("API canceled, fetchCallErrorData: ", err.message); // "my message for cancellation"
                    } else{
                    console.log("API promise error, fetchCallErrorData: ",err)
                    }
                    //return;
                });
            } catch (error) {
                console.log("error: ",error);
            }      
        })

        // On Authorization error, do the following:
        authPromise.catch(err=>{
            console.log("Auth error, fetchCallErrorData:",err)
        });//end the auth return
    } //end fetchCallErrorData

    /*
    * @brief Helper method to fetch the call error data
    */
    fetchCallLatencyData() {
            // Define the API lambda to launch
            let authPromise = Auth.currentSession();
            authPromise.then((auth) => {      
                let apiName = "TrifectaAPI";
                let path = "/handleStaffPerformance";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode:'calllatency',  
                        activeFilters: this.state.activeFilters,
                        secondaryAxis: this.state.secondaryAxis,
                        clientid: this.props.groupconfig.group,
                    }
                };
    
                try {
                    // Launch the API request
                    const apiPromise = API.post(apiName, path, myInit)
    
                    // Record the promise in the list of waiting to complete
                    this.setState(prevState => {
                        const promises = prevState.unfulfilledPromises;
                        promises.push(apiPromise);
                        return{unfulfilledPromises:promises}
                    });  
            
                    // On promise completion, do the following:
                    apiPromise.then( _data =>{
                        try {                            
                            // Remove the fulfilled promise from the list
                            this.setState(prevState => {
                                const promises = prevState.unfulfilledPromises;
                                const index = (promises||[]).indexOf(apiPromise);
                                if (index > -1) { // only splice array when item is found
                                    promises.splice(index, 1);
                                }
                                return{unfulfilledPromises:promises}
                            });  
                        } catch (error) {
                            console.log("Then error, fetchCallLatencyData: ",error);
                        }
                
                        try {
                            // Is the return valid
                            if(_data.error === false) {
                                // Generate the xaxis data
                                let {xdata,xdatasecondary} = this.getXAxisData(this.state.startDate, this.state.endDate, 
                                    this.state.activeFilters.e3person, this.state.activeFilters.bin, this.state.secondaryAxis, this.state.stafflist);
    
                                // Define empty arrays
                                let boxplot = new Array(xdata.length).fill(null).map(()=>new Array(5).fill(null));
    
                                // Loop over each row of the response
                                for( const row of _data.response ) {
                                    // Get the xdata and xdatasecondary without spaces
                                    let primary = row.xdata.replace(/ +(?= )/g,'');
                                    let secondary = row.xdatasecondary ? row.xdatasecondary.replace(/ +(?= )/g,'') : null;
    
                                    // Get the index of the corresponding entry on the xaxis
                                    let index = xdatasecondary ? xdata.indexOf(primary,xdatasecondary.indexOf(secondary)*this.state.stafflist.length) : xdata.indexOf(primary);

                                    // Copy the SQL data to the index
                                    if(index >=0) {
                                        boxplot[index][0] = Number(row.q0); //min
                                        boxplot[index][1] = Number(row.q1); //1st quartile
                                        boxplot[index][2] = Number(row.q2); //median
                                        boxplot[index][3] = Number(row.q3); //3rd quartile
                                        boxplot[index][4] = Number(row.q4); //max
                                    }
                                }

                                let latencyRanking = [];
                                for (const row of _data.rank) {
                                    // Is this staffer in the array
                                    if(this.state.stafflist.indexOf(row.reviewer) > -1) {
                                        latencyRanking.push({
                                            rank: latencyRanking.length+1,
                                            staff: row.reviewer,
                                            total: Number(row.total).toFixed(1)
                                        });
                                    }
                                }
    
                                // Update the state
                                let newData = {xdata : xdata, xdatasecondary : xdatasecondary, boxplot: boxplot};
                                this.setState({loadedLatencyData: true, latencyData : newData, latencyRanking : latencyRanking});
                            }
                        } catch (error) { 
                            console.log("Data error, fetchCallLatencyData: ",error);
                        }
                    });//end then processing on promise
    
                    // On API error, do the following:
                    apiPromise.catch(err => {
                        // ARe we cancelling the promise
                        if (API.isCancel(err)) {
                        // Log the API request has been cancelled
                        console.log("API canceled, fetchCallLatencyData: ", err.message); // "my message for cancellation"
                        } else{
                        console.log("API promise error, fetchCallLatencyData: ",err)
                        }
                        //return;
                    });
                } catch (error) {
                    console.log("error: ",error);
                }      
            })
    
            // On Authorization error, do the following:
            authPromise.catch(err=>{
                console.log("Auth error, fetchCallLatencyData:",err)
            });//end the auth return
        } //end fetchCallErrorData
    
    /*
    * @brief Helper method to fetch the total calls data
    */
    fetchTotalCallsData() {
            // Define the API lambda to launch
            let authPromise = Auth.currentSession();
            authPromise.then((auth) => {      
                let apiName = "TrifectaAPI";
                let path = "/handleStaffPerformance";
                let myInit = {
                    body: {
                        token: auth.idToken.jwtToken,
                        mode:'callins',  
                        activeFilters: this.state.activeFilters,
                        secondaryAxis: this.state.secondaryAxis,
                        clientid: this.props.groupconfig.group,
                    }
                };
    
                try {
                    // Launch the API request
                    const apiPromise = API.post(apiName, path, myInit)
    
                    // Record the promise in the list of waiting to complete
                    this.setState(prevState => {
                        const promises = prevState.unfulfilledPromises;
                        promises.push(apiPromise);
                        return{unfulfilledPromises:promises}
                    });  
            
                    // On promise completion, do the following:
                    apiPromise.then( _data =>{
                        try {
                            // Remove the fulfilled promise from the list
                            this.setState(prevState => {
                            const promises = prevState.unfulfilledPromises;
                            const index = (promises||[]).indexOf(apiPromise);
                            if (index > -1) { // only splice array when item is found
                                promises.splice(index, 1);
                            }
                            return{unfulfilledPromises:promises}
                            });  
                        } catch (error) {
                            console.log("Then error, fetchTotalCallsData: ",error);
                        }
                
                        try {
                            // Is the return valid
                            if(_data.error === false) {    
                                // Generate the xaxis data
                                let {xdata,xdatasecondary} = this.getXAxisData(this.state.startDate, this.state.endDate, 
                                    this.state.activeFilters.e3person, this.state.activeFilters.bin, this.state.secondaryAxis, this.state.stafflist);
    
                                // Get a list of lowercase site names
                                let sites = [];
                                this.state.sitelist.forEach( site => {sites.push(site.toLowerCase().replace(/\s+/g,''));} )

                                // Define empty array for the data series
                                let series = new Array(sites.length).fill(null).map(()=>new Array(xdata.length).fill(Number(0)));
                                let total = new Array(xdata.length).fill(Number(0));

                                // Loop over each row
                                for( const row of _data.response ) {
                                    
                                    // Get the xdata and xdatasecondary without spaces
                                    let primary = row.xdata.replace(/ +(?= )/g,'');
                                    let secondary = row.xdatasecondary ? row.xdatasecondary.replace(/ +(?= )/g,'') : null;
    
                                    // Get the index of the corresponding entry on the xaxis
                                    let xIndex = xdatasecondary ? xdata.indexOf(primary,xdatasecondary.indexOf(secondary)*this.state.stafflist.length) : xdata.indexOf(primary);
                                    let yIndex = sites.indexOf(row.site);

                                    // Copy the SQL data to the index
                                    if(xIndex >= 0 && yIndex >= 0) {
                                        series[yIndex][xIndex] = Number(row.count);
        
                                        // Sum the entries for easier data labels
                                        total[xIndex] += series[yIndex][xIndex];
                                    }
                                }                   
    
                                // Update the state
                                let newData = {xdata : xdata, xdatasecondary : xdatasecondary, series: series, total: total};
                                this.setState({loadedCallData: true, callData : newData});
                            }
                        } catch (error) { 
                            console.log("Data error, fetchTotalCallsData: ",error);
                        }
                    });//end then processing on promise
    
                    // On API error, do the following:
                    apiPromise.catch(err => {
                        // ARe we cancelling the promise
                        if (API.isCancel(err)) {
                        // Log the API request has been cancelled
                        console.log("API canceled, fetchTotalCallsData: ", err.message); // "my message for cancellation"
                        } else{
                        console.log("API promise error, fetchTotalCallsData: ",err)
                        }
                        //return;
                    });
                } catch (error) {
                    console.log("error: ",error);
                }      
            })
    
            // On Authorization error, do the following:
            authPromise.catch(err=>{
              console.log("Auth error, fetchTotalCallsData:",err)
            });//end the auth return
    } //end fetchTotalCallsData

    onDataChange(_data){
        // console.log("Received: ",_data);
        // console.log("Data change triggered");
        switch(_data.src){
            default:
            case "shift":{
                // console.log("Updated data returned from ", _data.src,_data.data);
                this.setState({renderedCharts:_data.data});
            }break;

        }
    }//end onDataChange

    /*
    * @brief React lifecyle method called to generate the visual elements on the page. Items declared inside the return() are displayed.
    */
    render() {
        /*
        Set style for the rows in the table     
        */
        // console.log("activeFilters:" ,this.state.activeFilters,this.state.filterKeys)
        
        // try {
        //     console.log("Active date: ",this.state.startDate.format('YYYY-MM-DD'), this.state.startDate_shift.format('YYYY-MM-DD'));
        // } catch (error) {}
        // console.log("CallinFilter:" ,this.state.selectedFilters_shift)
        return (
            <div className="staffperformance" >                                     {/* Definition of the Staff Performance View */}

                {/* API invocation with retry count change*/}
                {(this.props.groupconfig.bLoaded && this.state.stafflist) ?
                    <ApiCaller apiCall={this.getApiCall} onApiResult={() => {}} onLoadingState={this.onLoadingState}
                        retryCount = {this.state.retryCount} />:null}

                <div className='toprow'>                                            {/* Encapsulates the internal tabs, fitlers, checkboxes*/}
                    <div className="viewTabs">                                      {/* Definition of the tabs list */}
                        {<Tabs className='viewSelection'                            
                            defaultActiveKey={this.state.activeView}
                            unmountOnExit={true} mountOnEnter={true} 
                            id='uncontrolled-tab-example' 
                            activeKey={this.state.activeView} 
                            onSelect={(k)=> { this.onTabSelected(k) }}>
                            {this.state.tabViews.map((type) => {
                                return(  <Tab key={type.key} eventKey={type.key} title={type.name}/> )
                            })} 
                        </Tabs> }
                    </div>

                    <div className = "topRight">                                    {/* Encapsulates the checkboxes and filters */}
                    <React.Fragment>
                        <ComparisonCheckbox className = "comparison-checkboxes" 
                                onBoxSelected = {this.onBoxSelected} boxState = {this.state.secondaryAxis}
                                view = {this.state.activeView}
                        />
                        <DateFilterCheckboxes className = "date-checkboxes" 
                                onBoxSelected = {this.onBoxSelected} boxStates = {this.state.activeView==='shift'?this.state.boxStates_shift:this.state.boxStates}
                                view = {this.state.activeView}
                        />
                        {(this.state.activeView === 'shift') && 
                            <CallinFiltersView  className = "callin-filters" 
                                onFilterSelected = {this.onFilterSelected} onApply = {this.applyFilters} 
                                filterKeys = {this.state.filterKeys} modifiedTime = {this.state.filterUpdateTime}
                                // Specific to the selected tab:
                                filters = {this.state.filters_shift} 
                                selectedFilters = {this.state.selectedFilters_shift} 
                                startDate = {this.state.startDate_shift} 
                                endDate = {this.state.endDate_shift} 
                                
                            />
                        }
                        {(this.state.activeView === 'resp') && 
                            <CallinFiltersView  className = "callin-filters" 
                                onFilterSelected = {this.onFilterSelected} onApply = {this.applyFilters} 
                                filterKeys = {this.state.filterKeys} modifiedTime = {this.state.filterUpdateTime}
                                // Specific to the selected tab:
                                filters = {this.state.filters} 
                                selectedFilters = {this.state.selectedFilters} 
                                startDate = {this.state.startDate} 
                                endDate = {this.state.endDate} 
                                
                            />
                        }
                        {this.state.activeView === 'acc' && <div className="spacer"/>}
                    </React.Fragment>                                  
                        
                    </div>
                    
                </div>
                <div className="performancecontent" >                               {/* Encapsulates the content of each tab */}
                    {/* <div> */}
                    {this.state.activeView==='stats' && 
                        <div className="statisticstab">

                            {/* <AverageReviewTime
                                filters = {this.state.activeFilters} 
                                startDate = {this.state.startDate} endDate = {this.state.endDate} 
                                onDataReturn = {(_data)=>this.setState({averageReviewData: _data})}
                                reviewData = {this.state.averageReviewData}
                            />  */}

                            <div className="title">Worst Performing No Calls and Late Calls</div>
                            {this.state.loadedErrorData === false ? <Spinner /> :
                            <WrappedTable keyField='rank' classes ={"error-table"}
                                data = {this.state.errorRanking?this.state.errorRanking.slice(0,3):null}
                                columns = {[
                                    {dataField: 'rank', text: 'Rank',editable:false,},
                                    {dataField: 'staff', text: 'Staff Member',editable:false,},
                                    {dataField: 'total', text: 'Total Call Errors',editable:false},
                                ]}
                                defaultSorted={[
                                    {dataField: 'rank', order: 'asc'}, // how things should be sorted by
                                ]} // default when first displayed
                            />
                            }
                            <div className="spacer"/>
                            <div className="title">Worst Performing Severe Drowsiness Call Latency</div>
                            {this.state.loadedLatencyData === false ? <Spinner /> :
                            <WrappedTable keyField='rank' classes ={"error-table"}
                                data = {this.state.latencyRanking?this.state.latencyRanking.slice(0,3):null}
                                columns = {[
                                    {dataField: 'rank', text: 'Rank',editable:false,},
                                    {dataField: 'staff', text: 'Staff Member',editable:false,},
                                    {dataField: 'total', text: 'Average Call Latency (min)',editable:false},
                                ]}
                                defaultSorted={[
                                    {dataField: 'rank', order: 'asc'}, // how things should be sorted by
                                ]} // default when first displayed
                            />
                            }
                            <div className="spacer"/>
                            <div className="title">Worst Performing DVR Errors</div>
                            {this.state.loadedFPData === false ? <Spinner /> :
                            <WrappedTable keyField='rank' classes ={"error-table"}
                                data = {this.state.fpRanking?this.state.fpRanking.slice(0,3):null}
                                columns = {[
                                    {dataField: 'rank', text: 'Rank',editable:false,},
                                    {dataField: 'staff', text: 'Staff Member',editable:false,},
                                    {dataField: 'total', text: 'Total DVR Errors',editable:false},
                                ]}
                                defaultSorted={[
                                    {dataField: 'rank', order: 'asc'}, // how things should be sorted by
                                ]} // default when first displayed
                            />
                            }
                            <div className="spacer"/>
                            <div className="title">Worst Performing Severe Drowsiness Errors</div>
                            {this.state.loadedFPData === false ? <Spinner /> :
                            <WrappedTable keyField='rank' classes ={"error-table"}
                                data = {this.state.sdRanking?this.state.sdRanking.slice(0,3):null}
                                columns = {[
                                    {dataField: 'rank', text: 'Rank',editable:false,},
                                    {dataField: 'staff', text: 'Staff Member',editable:false,},
                                    {dataField: 'total', text: 'Total Severe Drowsiness Errors',editable:false},
                                ]}
                                defaultSorted={[
                                    {dataField: 'rank', order: 'asc'}, // how things should be sorted by
                                ]} // default when first displayed
                            />
                            }
                        </div>
                    }
                    {this.state.activeView==='resp' && 
                        <div className="responstab">
                            {this.state.loadedErrorData === false ? <Spinner /> : 
                            <CallErrorsChart data = {this.state.errorData} bin = {this.state.activeFilters.bin}
                                staff = {this.state.activeFilters.e3person} xmin = {this.state.xmin} 
                                xmax = {this.state.xmax} smin = {this.state.smin} smax = {this.state.smax} 
                                stafflist = {this.state.sitelist} sitelist = {this.state.sitelist}/> }
                            <div className="spacer"/>
                            {(this.state.loadedCallData === false || this.state.loadedErrorData === false) ? <Spinner /> : 
                            <TotalCallsChart data = {this.state.callData} bin = {this.state.activeFilters.bin}
                                staff = {this.state.activeFilters.e3person} xmin = {this.state.xmin} 
                                xmax = {this.state.xmax} smin = {this.state.smin} smax = {this.state.smax} 
                                stafflist = {this.state.sitelist} sitelist = {this.state.sitelist}/> }
                            <div className="spacer"/>
                            {(this.state.loadedLatencyData === false || this.state.loadedErrorData === false) ? <Spinner /> : 
                            <CallLatencyChart data = {this.state.latencyData} bin = {this.state.activeFilters.bin}
                                staff = {this.state.activeFilters.e3person} xmin = {this.state.xmin} 
                                xmax = {this.state.xmax} smin = {this.state.smin} smax = {this.state.smax} 
                                stafflist = {this.state.sitelist} sitelist = {this.state.sitelist}/> }                            
                        </div>
                    }
                    {this.state.activeView==='acc' && 
                        <div className="accuracytab">
                            {(this.state.loadedFPData === false || this.state.loadedCallData === false) ? <Spinner /> : 
                            <FalsePositiveChart data = {this.state.fpData} bin = {'Staff'}
                                staff = {'All'} xmin = {0} xmax = {this.state.fpData.total.length}
                                smin = {null} smax = {null}
                                stafflist = {this.state.sitelist} sitelist = {this.state.sitelist}/> }
                        </div>
                    }
                    
                    {isPermissionSet(this.props.groupconfig,"staffperformance",{shift:true}) && 
                        <div className ={this.state.activeView==='shift'?'shiftsummary':'hidden'}>
                            <SummaryShift_loading
                                // isSelected={this.state.activeView==='shift'}                    
                                isSelected={true}                    
                                renderedCharts = {this.state.renderedCharts}
                                onDataChange = {this.onDataChange}
                                activeFilters = {this.state.activeFilters_shift}
                                staffDetails = {this.state.staffDetails}
                            />    
                        </div>
                    }
                    
                    {/* </div> */}
                </div>
            </div>
        )
    } //end render

} //end StaffPerformanceView



///////////////////////////////////////////////////////
// Extenal exports
///////////////////////////////////////////////////////
export { StaffPerformanceView };